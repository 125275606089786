import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Components/Loading";
import moment from "moment";
import Footer from "../Components/Footer";
import "../Style/Services.css";
import { FaSearch } from "react-icons/fa";
import { BsArrowDownUp } from "react-icons/bs";
import { servicedetails } from "../Redux/Action/action";
import { AiOutlineFolderView } from "react-icons/ai";
import "../Style/Approvedgarages.css"
import Navbar2 from "../Components/Navbar2";
import { useSelector } from "react-redux";

function Approvegarages() {
    const [detials, setDetials] = useState([]);
    const [filterdata, setFilterdata] = useState("");
    const [startdate, setstartdate] = useState(" ");
    const [endDate, setendDate] = useState("");
    // const [loading, setloading] = useState(false);
    const [IsLoading, setIsLoading] = useState(false);
    const [appgarages, setappgarages] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [approve, setapprove] = useState([]);
    const [selectedGarages, setSelectedGarages] = useState([]);
    const BASEURL = process.env.REACT_APP_BASEURL;
    const authToken = window.sessionStorage.getItem("auth-token");
    const selector = useSelector((state) => state.LoginReduceruser);
    // let approve = []
    let updatedApprove = []



    function Fetchdetails() {
        setIsLoading(true);
        axios
            .get(`${BASEURL}/getGarageListforApproving`, {
                headers: {
                    "auth-token": authToken,
                },
            })
            .then((response) => {
                setDetials(response.data.recordset);

                setIsLoading(false);
            })
            .catch(() => {
                alert("Internal server error! please try after some time");
                setIsLoading(false);
                // navigate("/");
            });
    }
    // axios.post(`${BASEURL}/approveGarage`, { GARAGEID: approve, GARAGEAPPROVEDBY: "" })
    useEffect(Fetchdetails, []);
    // const approve = []

    function ApprovedGarages() {
        if (approve.length === 0) {
            alert("Please select the Garages!")
        } else {
            setIsLoading(true);

            const confirmed = window.confirm("Are you sure you want to Approve?");

            if (confirmed) {
                approve.forEach((item) => {

                    axios.post(`${BASEURL}/approveGarage`, { GARAGEID: item, GARAGEAPPROVEDBY: selector.email }, {
                        headers: {
                            "auth-token": authToken,
                        },
                    }).then((response) => {
                        // setDetials(response.data.recordset);


                        // setloading(false);
                        setIsLoading(false);
                    })
                        .catch(() => {
                            alert("Internal server error! please try after some time");
                            setIsLoading(false);
                            // navigate("/");
                        });
                });
            } else {
                setIsLoading(false);
            }
        }
    }

    function handleCheckboxChange(GARAGENUMBER) {
        const isGarageSelected = approve.includes(GARAGENUMBER);

        if (isGarageSelected) {

            updatedApprove = approve.filter((num) => num !== GARAGENUMBER);


            setapprove(updatedApprove);



        } else {

            setapprove([...approve, GARAGENUMBER]);

        }


    }

    if (IsLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Navbar2 />

            <header className="header__of__main" style={{ margin: "10px" }}>
                <h1 className="header__of__page"> Approve Services</h1>
            </header>


            <div className="Approve_btn">
                <button className="button_branch_save_app" type="button" onClick={ApprovedGarages} >Approve</button>
            </div>
            <div className="service_detials">
                <table className="table_1">
                    <thead>
                        <tr>
                            <th></th>
                            <th>GARAGE NUMBER</th>
                            <th>GARAGE NAME</th>
                            <th>GARAGE CITY
                            </th>
                            <th>GARAGE COUNTRY
                            </th>
                            <th>GARAGE EMAIL</th>


                            <th>GARAGE SPECIALIZATION
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {detials.map((item) => (
                            <tr>
                                <td> <input
                                    type="checkbox"
                                    checked={approve.includes(item.GARAGENUMBER)}
                                    onChange={() => handleCheckboxChange(item.GARAGENUMBER)}
                                /></td>
                                <td>{item.GARAGENUMBER}</td>
                                <td>{item.GARAGENAME}</td>
                                <td>{item.GARAGECITY}</td>
                                <td>{item.GARAGECOUNTRY}</td>
                                <td style={{ textTransform: "lowercase" }}>{item.GARAGEEMAIL}</td>
                                <td>{item.GARAGESPECIALIZATION}</td>

                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default Approvegarages