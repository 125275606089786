import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Style/VehicleRegistration.css";
import axios from "axios";
import Loading from "../Components/Loading";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { BiSolidUserAccount, BiUserPin } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { BsTruck } from "react-icons/bs";
import { PiEngine } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
function Vehicleregistration() {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.LoginReduceruser);

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  var state = selector;
  console.log(selector);

  const initialValue = {
    chassis_no: "",
    engine_no: "",
    registration_no: "",
    client_no: state.clientCode,
    vehicle_make: "",
    vehicle_model: "",
    vin_number: "",
    created_by: state.name,
    date: "",
  };

  const [vehinputs, SetVehinputs] = useState(initialValue);
  const [isloading, Setloading] = useState(true);
  const dispatch = useDispatch();
  function handleInputs(e) {
    const { name, value } = e.target;
    SetVehinputs({ ...vehinputs, [name]: value });
  }
  function registeration(e) {
    e.preventDefault();

    const confirmed = window.confirm("Are you sure you want to register?");

    if (confirmed) {
      Setloading(true);
      axios
        .post(`${BASEURL}/saveclientvehicle`, vehinputs, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          alert(response.data.message);
          Setloading(false);
          window.location.reload();
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          Setloading(false);
          // navigate("/");
        });
    } else {
      // window.location.reload()
      Setloading(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      Setloading(false);
    }, 2000);
  }, []);
  if (isloading) {
    return (
      <div>
        {" "}
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <Navbar />
      <div style={{ padding: "20px" }}>
        <div className="vehicle__registration">
          <form className="forM__style__vehicle__reg" onSubmit={registeration}>
            <header
              className="header__of__main"
              style={{ margin: "0px", marginLeft: "0px" }}
            >
              <h1
                className="header__of__page"
                style={{ margin: "0px", margin: "0px 0px 10px 0px" }}
              >
                {" "}
                Vehicle Registration
              </h1>
            </header>
            <div className="section">
              <div className="input__group code__flex">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px" }}
                  >
                    {" "}
                    <BiSolidUserAccount />
                  </span>{" "}
                  Client Code : &nbsp;
                </p>
                <p>
                  <span style={{ color: "red" }}>{state.clientCode}</span>
                </p>
              </div>
              <div className="input__group code__flex">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px" }}
                  >
                    <BiUserPin />{" "}
                  </span>{" "}
                  Created By : &nbsp;
                </p>
                <p className="created_by">
                  <span style={{ color: "red" }}>{state.name}</span>
                </p>
              </div>
              <div className="input__group">
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px", width: "25px" }}
                  >
                    <MdOutlineConfirmationNumber />{" "}
                  </span>{" "}
                  Chassis No :
                </p>
                <input
                  required
                  type="text"
                  name="chassis_no"
                  placeholder="Enter Chassis Number"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px" }}
                  >
                    <PiEngine />{" "}
                  </span>{" "}
                  Engine Number :
                </p>
                <input
                  required
                  type="text"
                  name="engine_no"
                  placeholder="Enter Engine Number"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px", width: "25px" }}
                  >
                    <MdOutlineConfirmationNumber />{" "}
                  </span>{" "}
                  Registration Number :
                </p>
                <input
                  required
                  type="text"
                  name="registration_no"
                  placeholder="Enter Registration Number"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px", width: "25px" }}
                  >
                    <MdOutlinePrecisionManufacturing />{" "}
                  </span>{" "}
                  Vehicle Manufactured :
                </p>
                <input
                  required
                  type="text"
                  name="vehicle_make"
                  placeholder="Enter Vehicle made By"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px", width: "25px" }}
                  >
                    <BsTruck />{" "}
                  </span>{" "}
                  Vehicle Model :
                </p>
                <input
                  required
                  type="text"
                  name="vehicle_model"
                  placeholder="Enter Vehicle Model"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "20px", width: "25px" }}
                  >
                    <MdOutlineConfirmationNumber />{" "}
                  </span>{" "}
                  Vehicle VIN Number :
                </p>
                <input
                  required
                  type="text"
                  name="vin_number"
                  placeholder="Enter Vehicle VIN"
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "15px", width: "15px" }}
                  >
                    <BsCalendarDate />{" "}
                  </span>{" "}
                  Date :
                </p>
                <input
                  required
                  onChange={(e) => handleInputs(e)}
                  type="date"
                  name="date"
                />
              </div>
            </div>
            <div className="btn__Submit__reg">
              <p>
                <p
                  type="button"
                  onClick={() => window.location.reload()}
                  className="button"
                >
                  Clear
                </p>
              </p>
              <p>
                <button className="button" type="submit">
                  Register
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
export default Vehicleregistration;
