import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";
import { garagedetails } from "../Redux/Action/action";
import { branchdetails } from "../Redux/Action/action";
import { FaSearch } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc"
import { GrAdd, GrClose } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx"
import "../Style/garagedetails.css";
import Loading from "../Components/Loading";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { BiSolidAddToQueue } from "react-icons/bi";
import { useSelector } from "react-redux";
function Listofgarages() {
  const [getgarages, setGetgarages] = useState([]);
  const [Filterdata, setFilterdata] = useState("");
  const [sortBy, setSortBy] = useState("GARAGENUMBER");
  const [sortOrder, setSortOrder] = useState("desc");
  const [IsLoading, setIsLoading] = useState("false");
  const [approve, setapprove] = useState([]);
  const [Appgarages, setAppgarages] = useState("")
  const [message, setmessage] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let updatedApprove = []
  const selector = useSelector((state) => state.LoginReduceruser);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");


  // function filterFunction(item, filterData, filterType) {
  // 
  //   if (filterData && filterType) {
  //     const searchText = filterData.toLowerCase().split(" ");
  //     if (filterData) {
  //       return searchText.every((part) => {
  //         return (
  //           (item.GARAGENAME && item.GARAGENAME.toLowerCase().includes(part)) ||
  //           (item.GARAGECOUNTRY &&
  //             item.GARAGECOUNTRY.toLowerCase().includes(part)) ||
  //           (item.GARAGEEMAIL && item.GARAGEEMAIL.toLowerCase().includes(part)) ||
  //           (item.GARAGAEPHONE && item.GARAGAEPHONE.toLowerCase().includes(part)) ||
  //           (item.GARAGECITY && item.GARAGECITY.toLowerCase().includes(part)) ||
  //           (item.GARAGENUMBER &&
  //             item.GARAGENUMBER.toString().toLowerCase().includes(part))
  //         );
  //       });
  //     }

  //     const lowerCaseFilterType = filterType
  //     if (lowerCaseFilterType === "Confirmed") {
  //       return item.APPROVED === true;
  //     } else if (lowerCaseFilterType !== "Confirmed") {
  //       return item.APPROVED !== true;
  //     }
  //   } else {
  //     return item
  //   }
  // }
  function filterFunction(item, filterData, filterType) {


    let dataFlag = true;
    let typeFlag = true;

    if (filterData) {
      const searchText = filterData.toLowerCase().split(" ");
      dataFlag = searchText.every((part) => {
        return (
          (item.GARAGENAME && item.GARAGENAME.toLowerCase().includes(part)) ||
          (item.GARAGECOUNTRY && item.GARAGECOUNTRY.toLowerCase().includes(part)) ||
          (item.GARAGEEMAIL && item.GARAGEEMAIL.toLowerCase().includes(part)) ||
          (item.GARAGEPHONE && item.GARAGEPHONE.toLowerCase().includes(part)) ||
          (item.GARAGECITY && item.GARAGECITY.toLowerCase().includes(part)) ||
          (item.GARAGENUMBER &&
            item.GARAGENUMBER.toString().toLowerCase().includes(part))
        );
      });
    }

    if (filterType) {
      const lowerCaseFilterType = filterType.toLowerCase();
      if (lowerCaseFilterType === "confirmed") {
        typeFlag = item.APPROVED === true;
      } else if (lowerCaseFilterType === "not confirmed") {
        typeFlag = item.APPROVED !== true;
      }
    }
    return dataFlag && typeFlag;
  }

  const garagefilter = getgarages.filter((item) =>
    filterFunction(item, Filterdata, Appgarages)
  );
  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedGarages = [...garagefilter].sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (sortOrder === "asc") {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
      return 0;
    }
  });


  async function ApprovedGarages() {
    if (approve.length === 0) {
      alert("Please select the Garages!");
      return;
    }

    setIsLoading(true);
    const confirmed = window.confirm("Are you sure you want to Approve?");

    if (confirmed) {
      try {
        await Promise.all(approve.map(async (item) => {

          const response = await axios.post(`${BASEURL}/approveGarage`, { GARAGEID: item, GARAGEAPPROVEDBY: selector.email }, {
            headers: {
              "auth-token": authToken,
            },
          });

        }));

        setIsLoading(false);
        alert("Garage(s) approved!");
        window.location.reload();
      } catch (error) {
        alert("Internal server error! Please try after some time");
        setIsLoading(false);
        console.error(error);
      }
    } else {
      setIsLoading(false);
    }
  }


  function handleCheckboxChange(GARAGENUMBER) {
    const isGarageSelected = approve.includes(GARAGENUMBER);

    if (isGarageSelected) {
      updatedApprove = approve.filter((num) => num !== GARAGENUMBER);
      setapprove(updatedApprove);

    } else {

      setapprove([...approve, GARAGENUMBER]);

    }


  }

  function displaygarages() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getgarages`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((request) => {
        setGetgarages(request.data.recordsets[0]);
        setIsLoading(false);

      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        // navigate("/");
      });
  }

  useEffect(displaygarages, []);

  if (IsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar2 />

      <header className="header__of__main" style={{ margin: "10px" }}>
        <h1 className="header__of__page"> List of Garages</h1>
      </header>

      <div className="search__for__list">

        <p>Search :&nbsp;</p>
        <input
          name="garagename"
          onChange={(e) => setFilterdata(e.target.value)}

        />
        <span className="search__icon">
          <FaSearch />
        </span>

        <div className="search__for__list">
          <p>Filter Garages : &nbsp;</p>
          <select onChange={(e) => {
            setAppgarages(e.target.value)

          }}>
            <option>Select</option>
            <option>CONFIRMED</option>
            <option>NOT CONFIRMED</option>
          </select>
        </div>
        <div >
          <button className="button_branch_save_app" type="button" onClick={ApprovedGarages} >Approve</button>
        </div>
      </div>
      <div className="table-container">
        <table className="table_1_">
          <thead>
            <tr>
              <th></th>
              <th
                style={{
                  textAlign: "right",
                  cursor: "pointer",
                  width: "190px",
                  padding: "4px",
                }}
                onClick={() => handleSort("GARAGENUMBER")}
              >
                Garage Number &nbsp;
                {sortOrder === "asc" ? (
                  <FaSortAmountUpAlt />
                ) : (
                  <FaSortAmountDownAlt />
                )}
              </th>
              <th>Garage Name</th>
              <th> Country</th>
              <th> City</th>
              <th> Email</th>
              <th style={{ textAlign: "right" }}> Phone Number</th>
              <th>View</th>
              <th>Add Branch </th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedGarages.map((item) => (
              <tr key={item.GARAGENUMBER}>
                <td> <input
                  type="checkbox"
                  // checked={approve.includes(item.GARAGENUMBER)}
                  disabled={item.APPROVED === true ? true : false}
                  onChange={() => handleCheckboxChange(item.GARAGENUMBER)}
                /></td>
                <td style={{ textAlign: "right", paddingRight: "35px" }}>
                  {item.GARAGENUMBER ? item.GARAGENUMBER : "N/A"}
                </td>
                <td>{item.GARAGENAME ? item.GARAGENAME : "N/A"}</td>
                <td>{item.GARAGECOUNTRY ? item.GARAGECOUNTRY : "N/A"}</td>
                <td>
                  {item.GARAGECITY === "SELECT" || null
                    ? "N/A"
                    : item.GARAGECITY}
                </td>
                <td
                  style={{
                    textTransform: "lowercase",
                  }}
                >
                  {item.GARAGEEMAIL ? item.GARAGEEMAIL : "N/A"}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.GARAGAEPHONE ? item.GARAGAEPHONE : "N/A"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(garagedetails(item));
                    navigate("/GarageDetails");
                  }}
                >
                  <span
                    className="icon__for__forms"
                    style={{
                      fontSize: "28px",
                      width: "32px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    <AiOutlineFolderView />{" "}
                  </span>{" "}
                </td>
                <td
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    dispatch(branchdetails(item));
                    navigate("/BranchRegistration");
                  }}
                >
                  <span
                    className="icon__for__forms"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      width: "32px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    <BiSolidAddToQueue />
                  </span>{" "}
                </td>
                <td className="tooltip"><tooltip >{item.APPROVED ? <i className="tooltiptext" title="Confirmed"> <VscVerifiedFilled style={{ fontSize: "27px", cursor: "pointer" }} /></i> : <i className="notconfirmed" title="Not Confirmed"><RxCross2 style={{ fontSize: "25px", cursor: "pointer", color: "red", width: "26px" }} /></i>}</tooltip></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
}

export default Listofgarages;
