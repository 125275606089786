import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Navbar2 from "../Components/Navbar2";
import Loading from "../Components/Loading";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
const BASEURL = process.env.REACT_APP_BASEURL;
const authToken = window.sessionStorage.getItem("auth-token");
const sessiontoken = window.sessionStorage.getItem("session-token");

function Updatebookingstatus() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [isloading, setIsLoading] = useState(false);
  const [listOfOrders, setListOfOrders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [startdate, setStartdate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [enddate, setEnddate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [search, setSearch] = useState("");
  const [statusforselected, setstatusforselected] = useState("");
  const [statusOptions] = useState([
    { value: "RECEIVED", label: "RECEIVED" },
    { value: "CONFIRMED", label: "CONFIRMED" },
    { value: "CANCELLED", label: "CANCELLED" },
  ]);
  const [countConfirmed, setCountConfirmed] = useState(0);
  const [countCanceled, setCountCanceled] = useState(0);
  const [countReceived, setCountReceived] = useState(0);
  const [statusOptionsfilter] = useState([
    { value: "", label: "" },
    { value: "RECEIVED", label: "RECEIVED" },
    { value: "CONFIRMED", label: "CONFIRMED" },
    { value: "CANCELLED", label: "CANCELLED" },
  ]);
  const selector = useSelector((state) => state.LoginReduceruser);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASEURL}/getAllServiceBookingDetails`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        const filteredOrders = response.data.filter(
          (order) => order.BOOKINGID !== null
        );
        setListOfOrders(filteredOrders);

        // Counting the statuses: Approved (Confirmed), Canceled, and Received
        const confirmedCount = filteredOrders.filter(
          (item) =>
            item["BOOKINGCONFIRMEDBYCLIENT"] === true &&
            item.BOOKINGCANCELLED === null
        ).length;

        const canceledCount = filteredOrders.filter(
          (item) => item.BOOKINGCANCELLED
        ).length;

        const receivedCount = filteredOrders.filter(
          (item) => item.RECEIVEDBYPROVIDER
        ).length;

        // Set the counts to the state
        setCountConfirmed(confirmedCount);
        setCountCanceled(canceledCount);
        setCountReceived(receivedCount);
        console.log(filteredOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filteredData = listOfOrders.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["ENQUIRYDATE"]);
    const eDate = new Date(item["ENQUIRYDATE"]);

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));
    const statusMatch =
      !statusforselected ||
      (statusforselected === "RECEIVED" && item.RECEIVEDBYPROVIDER) ||
      (statusforselected === "CONFIRMED" &&
        item["BOOKINGCONFIRMEDBYCLIENT"] === true &&
        item.BOOKINGCANCELLED === null) ||
      (statusforselected === "CANCELLED" && item.BOOKINGCANCELLED);
    const textMatch = searchData.every((term) => {
      return [
        "BOOKINGID",
        "CLIENT CODE",
        "BOOKINGCANCELLEDREASON",
        "RECEIVEDBY",
        "BOOKINGCANCELLED",
        "RECEIVEDBYPROVIDER",
        "BOOKINGCANCELLEDDATETIME",
        "PONOTES",
        "CLIENT ADDR1",
        "VAT",
        "NETAMT",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch && statusMatch;
  });
  console.log(filteredData);

  const handleCheckboxChange = (item, isChecked) => {
    if (item.STATUS) {
      if (isChecked) {
        setSelectedRows((prev) => [...prev, item]);
      } else {
        setSelectedRows((prev) =>
          prev.filter((row) => row.BOOKINGID !== item.BOOKINGID)
        );
      }
    } else {
      alert("Select the Status");
    }
  };

  const handleStatusChange = (item, selectedOption) => {
    const selectedStatus = selectedOption.value;

    // Condition to handle "CONFIRMED" status
    if (selectedStatus === "CONFIRMED") {
      // Ensure that RECEIVEDBYPROVIDER is true before allowing "CONFIRMED"
      if (item.RECEIVEDBYPROVIDER === true) {
        setListOfOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.BOOKINGID === item.BOOKINGID
              ? { ...order, STATUS: selectedStatus }
              : order
          )
        );

        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.map((row) =>
            row.BOOKINGID === item.BOOKINGID
              ? { ...row, STATUS: selectedStatus }
              : row
          )
        );
      } else {
        alert(
          "You cannot mark this booking as CONFIRMED unless it is RECEIVED."
        );
        return;
      }
    }
    // Condition to handle "CANCELLED" status
    else if (selectedStatus === "CANCELLED") {
      // Ensure that either BOOKINGCONFIRMEDBYCLIENT or RECEIVEDBYPROVIDER is true before allowing "CANCELLED"
      if (
        item.BOOKINGCONFIRMEDBYCLIENT === true ||
        item.RECEIVEDBYPROVIDER === true
      ) {
        setListOfOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.BOOKINGID === item.BOOKINGID
              ? { ...order, STATUS: selectedStatus }
              : order
          )
        );

        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.map((row) =>
            row.BOOKINGID === item.BOOKINGID
              ? { ...row, STATUS: selectedStatus }
              : row
          )
        );
      } else {
        alert(
          "You cannot mark this booking as CANCELLED unless it is CONFIRMED or RECEIVED."
        );
        return;
      }
    }
    // No condition for "RECEIVED" or other statuses
    else {
      setListOfOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.BOOKINGID === item.BOOKINGID
            ? { ...order, STATUS: selectedStatus }
            : order
        )
      );

      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.map((row) =>
          row.BOOKINGID === item.BOOKINGID
            ? { ...row, STATUS: selectedStatus }
            : row
        )
      );
    }
  };

  const handleNarrationChange = (item, narration) => {
    setListOfOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.BOOKINGID === item.BOOKINGID
          ? { ...order, NARRATION: narration }
          : order
      )
    );

    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.map((row) =>
        row.BOOKINGID === item.BOOKINGID
          ? { ...row, NARRATION: narration }
          : row
      )
    );
  };

  const confirmDetails = async () => {
    if (selectedRows.length === 0) {
      alert("Select at least one booking ID by clicking the checkbox.");
      return;
    }

    const incompleteRows = selectedRows.filter(
      (row) => !row.STATUS || !row.NARRATION
    );
    if (incompleteRows.length > 0) {
      alert(
        "Please ensure that all selected  booking ID  have both a status and narration filled in."
      );
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to update the selected  booking ID ?"
    );
    if (!confirmation) return;

    try {
      const requests = selectedRows.map((item) =>
        axios.post(
          `${BASEURL}/updateBookingStatus`,
          {
            BOOKINGID: item.BOOKINGID,
            STATUSTYPE: item.STATUS,
            STATUSNARRATION: item.NARRATION,
            JOBID: 0,
            QUOTEID: 0,
            STATUSUPDATEDBY: selector?.email,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
      );

      const responses = await Promise.all(requests);
      const messages = responses.map((response) => response.data.message);
      console.log(messages);
      alert("Status Updated");
      window.location.reload();
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred");
    }
  };
  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("BOOKING STATUS REPORT");

    const columnWidths = [25, 25, 40, 25, 25, 25, 25];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "Booking ID",
      "Received",
      "Received By",
      "Received Date",
      "Confirmed",
      "Confirmed Date Time",
      "Cancelled",
      "Cancelled Date Time",
      "Job No",
      "Quote No",
      "Status Type",
      "Narration",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the filteredData
    filteredData.forEach((item) => {
      const rowData = [
        item.BOOKINGID || "NA",
        item["RECEIVEDBYPROVIDER"] ? "YES" : "NA",
        item["RECEIVEDBY"] || "N/A",
        item.RECEIVEDDATETIME
          ? `${item.RECEIVEDDATETIME.slice(
              8,
              10
            )}/${item.RECEIVEDDATETIME.slice(
              5,
              7
            )}/${item.RECEIVEDDATETIME.slice(
              0,
              4
            )} ${item.RECEIVEDDATETIME.slice(11, 16)}`
          : "NA",
        item.BOOKINGCONFIRMEDBYCLIENT === true && item.BOOKINGCANCELLED === true
          ? "CANCELLED"
          : item.BOOKINGCONFIRMEDBYCLIENT === true
          ? "CONFIRMED"
          : "NA",
        item.BOOKINGCONFIRMEDDATETIME
          ? `${item.BOOKINGCONFIRMEDDATETIME.slice(
              8,
              10
            )}/${item.BOOKINGCONFIRMEDDATETIME.slice(
              5,
              7
            )}/${item.BOOKINGCONFIRMEDDATETIME.slice(
              0,
              4
            )} ${item.BOOKINGCONFIRMEDDATETIME.slice(11, 16)}`
          : "NA",
        item.BOOKINGCANCELLED ? "YES" : "NA",
        item.BOOKINGCANCELLEDDATETIME
          ? `${item.BOOKINGCANCELLEDDATETIME.slice(
              8,
              10
            )}/${item.BOOKINGCANCELLEDDATETIME.slice(
              5,
              7
            )}/${item.BOOKINGCANCELLEDDATETIME.slice(
              0,
              4
            )} ${item.BOOKINGCANCELLEDDATETIME.slice(11, 16)}`
          : "NA",
        item.JOBNO || "NA",
        item.QUOTENO || "NA",
        item.BOOKINGCANCELLED
          ? "CANCELLED"
          : item.RECEIVEDBYPROVIDER && item.BOOKINGCONFIRMEDBYCLIENT
          ? "CONFIRMED"
          : item.RECEIVEDBYPROVIDER
          ? "RECEIVED"
          : "N/A",
        item.BOOKINGCANCELLEDREASON
          ? item.BOOKINGCANCELLEDREASON
          : item.BOOKINGCONFIRMEDNARRATION
          ? item.BOOKINGCONFIRMEDNARRATION
          : item.RECEIVEDNARRATION
          ? item.RECEIVEDNARRATION
          : "N/A",
      ];

      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "BOOKING_STATUS_REPORT.xlsx";
      link.click();
    });
  };
  const PdfBookingData = () => {
    const doc = new jsPDF();

    // Set smaller font size for the title
    doc.setFontSize(10);
    doc.text("BOOKING STATUS REPORT", 10, 10);

    const columns = [
      { title: "Booking ID", dataKey: "BOOKINGID" },
      { title: "Received", dataKey: "RECEIVEDBYPROVIDER" },
      { title: "Received By", dataKey: "RECEIVEDBY" },
      { title: "Received Date", dataKey: "RECEIVEDDATETIME" },
      { title: "Confirmed", dataKey: "BOOKINGCONFIRMEDBYCLIENT" },
      { title: "Confirmed Date Time", dataKey: "BOOKINGCONFIRMEDDATETIME" },
      { title: "Cancelled", dataKey: "BOOKINGCANCELLED" },
      { title: "Cancelled Date Time", dataKey: "BOOKINGCANCELLEDDATETIME" },
      { title: "Job No", dataKey: "JOBNO" },
      { title: "Quote No", dataKey: "QUOTENO" },
      { title: "Status Type", dataKey: "STATUS" },
      { title: "Narration", dataKey: "NARRATION" },
    ];

    const data = filteredData.map((item) => ({
      BOOKINGID: item.BOOKINGID || "N/A",
      RECEIVEDBYPROVIDER: item.RECEIVEDBYPROVIDER ? "Yes" : "No",
      RECEIVEDBY: item.RECEIVEDBY || "N/A",
      RECEIVEDDATETIME: item.RECEIVEDDATETIME
        ? `${item.RECEIVEDDATETIME.slice(8, 10)}/${item.RECEIVEDDATETIME.slice(
            5,
            7
          )}/${item.RECEIVEDDATETIME.slice(0, 4)} ${item.RECEIVEDDATETIME.slice(
            11,
            16
          )}`
        : "N/A",
      BOOKINGCONFIRMEDBYCLIENT: item.BOOKINGCONFIRMEDBYCLIENT ? "Yes" : "No",
      BOOKINGCONFIRMEDDATETIME: item.BOOKINGCONFIRMEDDATETIME
        ? `${item.BOOKINGCONFIRMEDDATETIME.slice(
            8,
            10
          )}/${item.BOOKINGCONFIRMEDDATETIME.slice(
            5,
            7
          )}/${item.BOOKINGCONFIRMEDDATETIME.slice(
            0,
            4
          )} ${item.BOOKINGCONFIRMEDDATETIME.slice(11, 16)}`
        : "N/A",
      BOOKINGCANCELLED: item.BOOKINGCANCELLED ? "Yes" : "No",
      BOOKINGCANCELLEDDATETIME: item.BOOKINGCANCELLEDDATETIME
        ? `${item.BOOKINGCANCELLEDDATETIME.slice(
            8,
            10
          )}/${item.BOOKINGCANCELLEDDATETIME.slice(
            5,
            7
          )}/${item.BOOKINGCANCELLEDDATETIME.slice(
            0,
            4
          )} ${item.BOOKINGCANCELLEDDATETIME.slice(11, 16)}`
        : "N/A",
      JOBNO: item.JOBNO || "N/A",
      QUOTENO: item.QUOTENO || "N/A",
      STATUS: item.BOOKINGCANCELLED
        ? "CANCELLED"
        : item.RECEIVEDBYPROVIDER && item.BOOKINGCONFIRMEDBYCLIENT
        ? "CONFIRMED"
        : item.RECEIVEDBYPROVIDER
        ? "RECEIVED"
        : "N/A",

      NARRATION: item.BOOKINGCANCELLEDREASON
        ? item.BOOKINGCANCELLEDREASON
        : item.BOOKINGCONFIRMEDNARRATION
        ? item.BOOKINGCONFIRMEDNARRATION
        : item.RECEIVEDNARRATION
        ? item.RECEIVEDNARRATION
        : "N/A",
    }));

    doc.autoTable({
      head: [columns.map((col) => col.title)],
      body: data.map((row) => Object.values(row)),
      startY: 20,
      showHead: "firstPage",
      margin: { top: 10, bottom: 10, left: 5, right: 5 }, // Reduce margins
      theme: "grid", // Keeps table compact
      styles: {
        fontSize: 8, // Smaller font size for table content
        cellPadding: 2, // Reduce padding
      },
      columnStyles: {
        0: { cellWidth: 15 }, // Booking ID
        1: { cellWidth: 15 }, // Received
        2: { cellWidth: 18 }, // Received By
        3: { cellWidth: 15 }, // Reduced width for Received Date
        4: { cellWidth: 15 }, // Confirmed
        5: { cellWidth: 15 }, // Reduced width for Confirmed Date Time
        6: { cellWidth: 15 }, // Cancelled
        7: { cellWidth: 15 }, // Reduced width for Cancelled Date Time
        8: { cellWidth: 15 }, // Job No
        9: { cellWidth: 15 }, // Quote No
        10: { cellWidth: 18 }, // Status Type
        11: { cellWidth: 30 }, // Narration
      },
      rowPageBreak: "avoid", // Avoid breaking rows between pages
    });

    doc.save("Booking_Status_Report.pdf");
  };
  if (isloading) return <Loading />;

  return (
    <div>
      <Navbar2 />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "BOOKING LIST"}</h1>
      </header>
      <ul className="font-bold flex gap-3 ml-4">
        <li>
          Confirmed Orders:{" "}
          <span className="text-red-600">{countConfirmed}</span>
        </li>
        <li>
          Canceled Orders: <span className="text-red-600">{countCanceled}</span>
        </li>
        <li>
          Received Orders: <span className="text-red-600">{countReceived}</span>
        </li>
      </ul>
      <aside className="lg:flex items-center gap-4 px-2">
        <div className="lg:flex">
          <p className="lg:mx-2 text-[16px] font-bold">Search: </p>
          <input
            className="w-[280px] lg:mr-[50px] h-[30px]"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {/* <div className="items-center gap-3 lg:flex">
                    <p className="text-gray-700">Start Date</p>
                    <input
                        type="date"
                        onChange={e => setStartdate(e.target.value)}
                        value={startdate.split("T")[0]}
                        className="w-[130px]"
                    />
                </div>
                <div className="items-center gap-3 lg:flex">
                    <p className="text-gray-700">End Date</p>
                    <input
                        type="date"
                        onChange={e => setEnddate(e.target.value)}
                        value={enddate.split("T")[0]}
                        className="w-[130px]"
                    />
                </div> */}
        <p>Select status:</p>
        &nbsp;
        <Select
          className="w-[300px] mb-2 text-[16px] font-bold"
          onChange={(e) => {
            setstatusforselected(e.value);
          }}
          value={
            statusforselected
              ? { label: statusforselected, value: statusforselected }
              : { label: "", value: "" }
          }
          options={statusOptionsfilter}
          placeholder="Select Status"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62] lg:ml-[100px] text-white rounded-md px-3 py-1 hover:bg-sky-700 focus:outline-none focus:ring focus:border-blue-300"
          onClick={confirmDetails}
        >
          Update
        </button>
        <div className="flex lg:justify-between gap-3 cursor-pointer mt-1 lg:ml-10 lg:mx-0">
          <div>
            <RiFileExcel2Line onClick={ExcelStockData} size={35} />
          </div>
          <div>
            {" "}
            <BsFileEarmarkPdf onClick={PdfBookingData} size={35} />
          </div>
        </div>
      </aside>
      {/* <div className="lg:flex justify-end mb-1 lg:mr-6 lg:my-0 my-1">
                <button
                    className="lg:flex items-center justify-center border border-black bg-[#002d62] lg:ml-[100px] text-white rounded-md px-3 py-1 hover:bg-sky-700 focus:outline-none focus:ring focus:border-blue-300"
                    onClick={confirmDetails}
                >
                    Update
                </button>
            </div> */}
      <div className=" overflow-auto mb-[300px] h-[500px] px-5">
        <table className="table_1" style={{ fontSize: "12px" }}>
          <thead className="sticky top-0 z-999">
            <tr style={{ textTransform: "uppercase", zIndex: 500 }}>
              <td></td>

              <td>BOOKING ID</td>
              <td>RECEIVED</td>
              <td>RECEIVED BY</td>
              <td>RECEIVED DATE</td>
              <td className="">CONFIRMED</td>
              <td> CONFIRMED DATE TIME</td>
              <td>CANCELLED</td>
              <td className="text-right">CANCELLED DATE TIME</td>
              {/* <td className="text-right">CANCELLED REASON</td> */}
              <td className="text-right">JOB NO</td>
              <td className="text-right">QUOTE NO</td>

              <td>STATUS TYPE</td>
              <td>NARRATION</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} style={{ textTransform: "uppercase" }}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.some(
                        (row) => row.BOOKINGID === item.BOOKINGID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(item, e.target.checked)
                      }
                      // disabled={!item.BOOKINGSTATUS || !item.NARRATION}
                    />
                  </td>
                  <td>{item.BOOKINGID}</td>
                  <td>{item["RECEIVEDBYPROVIDER"] ? <TiTick /> : "NA"}</td>
                  <td>{item["RECEIVEDBY"] || "N/A"}</td>
                  <td>
                    {item.RECEIVEDDATETIME
                      ? `${item.RECEIVEDDATETIME.slice(
                          8,
                          10
                        )}/${item.RECEIVEDDATETIME.slice(
                          5,
                          7
                        )}/${item.RECEIVEDDATETIME.slice(0, 4)} ${
                          item.RECEIVEDDATETIME.slice(11, 16) // Extract time (HH:MM)
                        }`
                      : "NA"}
                  </td>
                  <td>
                    {item["BOOKINGCONFIRMEDBYCLIENT"] === true &&
                      item.BOOKINGCANCELLED === true && <IoClose />}
                    {item["BOOKINGCONFIRMEDBYCLIENT"] === true &&
                      item.BOOKINGCANCELLED === null && <TiTick />}
                    {item["BOOKINGCONFIRMEDBYCLIENT"] === null &&
                      item.BOOKINGCANCELLED === null &&
                      "N/A"}
                  </td>
                  <td>
                    {item.BOOKINGCONFIRMEDDATETIME
                      ? `${item.BOOKINGCONFIRMEDDATETIME.slice(
                          8,
                          10
                        )}/${item.BOOKINGCONFIRMEDDATETIME.slice(
                          5,
                          7
                        )}/${item.BOOKINGCONFIRMEDDATETIME.slice(0, 4)} ${
                          item.BOOKINGCONFIRMEDDATETIME.slice(11, 16) // Extract time (HH:MM)
                        }`
                      : "NA"}
                  </td>
                  <td className="">
                    {item.BOOKINGCANCELLED ? <TiTick /> : "NA"}
                  </td>
                  <td>
                    {item.BOOKINGCANCELLEDDATETIME
                      ? `${item.BOOKINGCANCELLEDDATETIME.slice(
                          8,
                          10
                        )}/${item.BOOKINGCANCELLEDDATETIME.slice(
                          5,
                          7
                        )}/${item.BOOKINGCANCELLEDDATETIME.slice(0, 4)} ${
                          item.BOOKINGCANCELLEDDATETIME.slice(11, 16) // Extract time (HH:MM)
                        }`
                      : "NA"}
                  </td>

                  <td>{item.JOBNO || "NA"}</td>
                  <td>{item.QUOTENO || "NA"}</td>

                  <td className="w-[300px]">
                    <Select
                      className="min-w-[250px] "
                      options={
                        item.BOOKINGCANCELLED === true &&
                        item.RECEIVEDBYPROVIDER === true &&
                        item.BOOKINGCONFIRMEDBYCLIENT === true
                          ? []
                          : item.RECEIVEDBYPROVIDER === true &&
                            item.BOOKINGCONFIRMEDBYCLIENT === true
                          ? statusOptions.filter(
                              (option) =>
                                option.value !== "RECEIVED" &&
                                option.value !== "CONFIRMED"
                            )
                          : item.RECEIVEDBYPROVIDER === true
                          ? statusOptions.filter(
                              (option) => option.value !== "RECEIVED"
                            )
                          : statusOptions
                      }
                      onChange={(selectedOption) =>
                        handleStatusChange(item, selectedOption)
                      }
                      placeholder="Select Status"
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={
                        statusOptions.find(
                          (option) => option.value === item.STATUS
                        ) ||
                        (item.BOOKINGCANCELLED
                          ? { value: "CANCELLED", label: "CANCELLED" }
                          : null) ||
                        (item.RECEIVEDBYPROVIDER &&
                        item.BOOKINGCONFIRMEDBYCLIENT
                          ? { value: "CONFIRMED", label: "CONFIRMED" }
                          : null) ||
                        (item.RECEIVEDBYPROVIDER
                          ? { value: "RECEIVED", label: "RECEIVED" }
                          : null)
                      }
                    />
                  </td>

                  <td>
                    <textarea
                      placeholder={
                        item.BOOKINGCANCELLEDREASON
                          ? item.BOOKINGCANCELLEDREASON
                          : item.BOOKINGCONFIRMEDNARRATION
                          ? item.BOOKINGCONFIRMEDNARRATION
                          : item.RECEIVEDNARRATION
                          ? item.RECEIVEDNARRATION
                          : ""
                      }
                      value={item.NARRATION || ""}
                      className="lg:w-96 textarea__main__bold rounded-md resize-none w-full placeholder-bold placeholder-black text-sm"
                      onChange={(e) =>
                        handleNarrationChange(item, e.target.value)
                      }
                    ></textarea>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {statusforselected && (
          <div className="p-2 font-bold">
            Total {statusforselected} :{" "}
            <span className="text-red-600">{filteredData?.length}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Updatebookingstatus;
