import React from "react";
import { Link } from "react-router-dom";
import BranchRegistration from "./BranchRegistration";
import Listofgarages from "./Listofgarages";
import "../Style/Home.css";
import Navbaruser1 from "../Components/Navbaruser1";
import Footer from "../Components/Footer";
import car1 from "../Image/car1.svg";
import car2 from "../Image/car2.svg";
import car3 from "../Image/car3.svg";
import service2 from "../Image/service2.svg";
import service3 from "../Image/service3.svg";
import service5 from "../Image/service5.svg";
import service7 from "../Image/service7.svg";
function UserDashboard() {
  return (
    <div className="bg-slate-100">
      <Navbaruser1 />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">
          {" "}
          Welcome to MarsTrack Service Booking
        </h1>
      </header>

      <section className="flex items-center justify-center gap-10 my-10 flex-wrap">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/UserBranchRegistration">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car1}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Branch Registration/Modification
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/Usergarageregistration">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={service5}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Garage Modification
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/Garageusers">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={service3}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Add Users
              </h2>
            </div>
          </Link>
        </div>
        {/* <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/bookedServices">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={service2}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Booking Lists
              </h2>
            </div>
          </Link>
        </div> */}
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/Updatebookingstatus">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={service7}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Booking List
              </h2>
            </div>
          </Link>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default UserDashboard;
