const initialState = {}

function garagereducer(state = initialState, action) {
    switch (action.type) {
        case 'GARAGENUMBER':
            return action.data
        default:
            return state
    }
}
export default garagereducer