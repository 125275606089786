import React from "react";
import { Link } from "react-router-dom";
import { GiMechanicGarage } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
function Navbar4() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div className="btn_links">
        <h2 className="navbar_home  font-bold text-lg"> Service Booking </h2>

        <h3 className="navbar_home_one ">
          <>
            <Link className="logout_link_2" to="/">
              &nbsp; Back &nbsp;
            </Link>
          </>
        </h3>
      </div>
    </div>
  );
}

export default Navbar4;
