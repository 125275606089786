import React, { useEffect, useState } from "react";
import "./LoginRegister.css";
import LoginPic from "../../Image/service4.svg";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { HiOutlineEye } from "react-icons/hi";
import { RiEyeOffLine } from "react-icons/ri";
import { Country, City } from "country-state-city";
import Select from "react-select";
import Loading from "../../Components/Loading";

const LoginForm = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");

  const stateCompany = useSelector((state) => state.SelCompany);
  const selCompany = stateCompany?.selectedCompany;
  const [activeForm, setActiveForm] = useState("signUp");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [servererror, setserverError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  let serverResponse = true;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [signuppassword, setSignupPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [signuppasswordShown, setsignuppasswordShown] = useState(false);
  const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
  const [universalSearch, setUniversalSearch] = useState("");
  let carts = [];
  carts = useSelector((state) => state?.cartreducer?.items);
  const [cartItems, setCartItems] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [CLIENTIDpass, setCLIENTIDpass] = useState("");
  const [OFFICEPHONEpass, setOFFICEPHONEpass] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const [search, setSearch] = useState("");
  const [NewCartNo, setNewCartNo] = useState("");
  const [EmaiAddress, setEmaiAddress] = useState("");
  const currentDate = new Date();
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [isloading, setisloading] = useState(false);

  const cashUser = useSelector(
    (state) => state?.cashUserInfoReducer?.selectedCashUser[0]
  );

  const divisionFromDashboard = useSelector(
    (state) => state?.divisionTrackerReducer
  );
  const getClientcode = () => {
    setisloading(true);
    Axios.get(`${BASEURL}/getNewClientCode`)
      .then((response) => {
        console.log(response?.data[0]?.NEWCLIENTID);
        setCLIENTIDpass(response?.data[0]?.NEWCLIENTID);

        setisloading(false);
      })

      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
    Axios.get(`${BASEURL}/getClientList`)
      .then((response) => {
        console.log(response?.data);

        setisloading(false);
      })

      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
  };
  useEffect(() => {
    getClientcode();
  }, []);
  // getNewClientCode /getClientList

  //   /insertClient
  // @CLIENTID NUMERIC,
  // @FIRSTNAME VARCHAR(100),
  // @LASTNAME VARCHAR(100),
  // @COUNTRY VARCHAR(100),
  // @CITY VARCHAR(100),
  // @OFFICEPHONE VARCHAR(100),
  // @CLIENTEMAIL VARCHAR(100),
  // @CLIENTPOSTALCODE VARCHAR(100),
  // @CLIENTADDRESS VARCHAR(500),
  // @CLIENTMOBILE VARCHAR(100),
  // @PASSWORD VARCHAR(100)
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.label);
    setCity("");

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(countryCities);
    } else {
      setCities([]);
    }
  };
  const switchToSignUp = () => {
    setActiveForm("signUp");
  };

  const switchToSignIn = () => {
    navigate("/");
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleSignupPassword = () => {
    setsignuppasswordShown(!signuppasswordShown);
  };

  const toggleConfirmPassword = () => {
    setconfirmpasswordShown(!confirmpasswordShown);
  };

  const handleSubmit = () => {
    if (firstName === "") {
      alert("First Name is empty");
      return;
    }
    if (lastName === "") {
      alert("Last Name is empty");
      return;
    }
    if (email === "") {
      alert("Email Address is empty");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Invalid email format");
      setIsLoading(false);
      return;
    }
    if (country === "") {
      alert("Country is empty");
      return;
    }
    if (city === "") {
      alert("City is empty");
      return;
    }
    if (postalCode === "") {
      alert("Postal Code is empty");
      return;
    }
    if (physicalAddress === "") {
      alert("Physical Address is empty");
      return;
    }
    if (mobileNo === "") {
      alert("Phone Number is empty");
      return;
    }
    if (OFFICEPHONEpass === "") {
      alert("Office Number is empty");
      return;
    }
    if (signuppassword === "") {
      alert("Password is empty");
      return;
    }
    if (confirmpassword === "") {
      alert("Password is empty");
      return;
    }
    if (signuppassword !== confirmpassword) {
      alert("Password is not matching");
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to submit the form?"
    );
    if (confirmed) {
      try {
        setIsLoading(true);
        Axios.post(
          `${BASEURL}/insertClient`,
          {
            CLIENTID: Number(CLIENTIDpass),
            FIRSTNAME: firstName,
            LASTNAME: lastName,
            COUNTRY: country,
            CITY: city,
            OFFICEPHONE: OFFICEPHONEpass,
            CLIENTEMAIL: email,
            CLIENTPOSTALCODE: postalCode,
            CLIENTADDRESS: physicalAddress,
            CLIENTMOBILE: mobileNo,
            PASSWORD: confirmpassword,
          },
          {
            headers: {
              "session-token": sessiontoken,
            },
          }
        )
          .then((response) => {
            alert(response.data.message);
            navigate("/");
            window.location.reload();
            setIsLoading(false);
          })
          .catch((error) => {
            alert(error.response.data.message);
            console.log(error);
            window.location.reload();
            setIsLoading(false);
          });
      } catch {
        setserverError(
          "No response from server or unmatched email or password"
        );
        setIsLoading(false);
      }
    } else {
      setserverError("Server is down. Please try again later.");
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="login__main">
      <div className="container">
        <div
          className={`form__style__up signUp ${
            activeForm === "signUp" ? "active-dx" : "inactive-dx"
          }`}
        >
          <h3 className="heading__h3">Create Your Account</h3>

          {/* ... (rest of the form) */}
          <div className="lg:flex justify-between gap-2">
            <aside className="lg:w-[47%]">
              <p>First Name</p>
              <input
                className="input__login w100 w-full"
                type="text"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                placeholder="Enter name"
                required
                autoComplete="off"
              />
            </aside>
            <aside className="lg:w-[47%]">
              <p>Last Name</p>
              <input
                className="input__login w100 w-full"
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                placeholder="Enter name"
                required
                autoComplete="off"
              />
            </aside>
          </div>
          <div className="lg:flex justify-between gap-2">
            <aside className="lg:w-[47%]">
              <p>Email</p>
              <input
                className="input__login w100"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
                required
                autoComplete="off"
              />
            </aside>
            <aside className="lg:w-[47%]">
              {" "}
              <p>Phone</p>
              <input
                className="input__login w100"
                type="number"
                placeholder="Enter Phone Number"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
                required
                autoComplete="off"
              />
            </aside>
            <aside className="lg:w-[47%]">
              {" "}
              <p>OFFICE PHONE </p>
              <input
                className="input__login w100"
                type="number"
                placeholder="Enter office Number"
                value={OFFICEPHONEpass}
                onChange={(e) => {
                  setOFFICEPHONEpass(e.target.value);
                }}
                required
                autoComplete="off"
              />
            </aside>
          </div>
          <div className="lg:flex justify-between gap-2">
            <aside className="lg:w-[47%]">
              {" "}
              <p>Country</p>
              <Select
                value={
                  country
                    ? { label: country, value: country }
                    : { label: "", value: "" }
                }
                onChange={handleCountryChange}
                options={[
                  // { label: " ", value: "" },
                  ...countries.map((item) => ({
                    label: item.name,
                    value: item.isoCode,
                  })),
                ]}
              />
            </aside>
            <aside className="lg:w-[47%]">
              <p>City</p>
              <Select
                value={
                  city ? { label: city, value: city } : { label: "", value: "" }
                }
                onChange={(e) => {
                  setCity(e.value);
                }}
                options={[
                  // { label: " ", value: "" },
                  ...cities.map((item) => ({
                    label: item.name,
                    value: item.name,
                  })),
                ]}
                onBlur={() => {
                  if (country && !city) {
                    alert("Kindly Enter City Details ");
                  }
                }}
              />
            </aside>
          </div>
          <p>Postal code</p>
          <input
            className="input__login w100"
            type="number"
            onChange={(e) => {
              setPostalCode(e.target.value);
            }}
            placeholder="Postal code"
            required
            autoComplete="off"
          />
          <p>Physical Address </p>
          <textarea
            className="input__login w100 max-h-[60px] min-h-[60px]"
            type="text"
            onChange={(e) => {
              setPhysicalAddress(e.target.value);
            }}
            placeholder="Physical Address"
            required
            autoComplete="off"
          />
          <div className="lg:flex justify-between gap-2">
            <aside className="lg:w-[47%]">
              <p>Enter Password</p>
              <div class="password-input-container">
                <input
                  className="input__login w100 password-input"
                  type={signuppasswordShown ? "" : "password"}
                  placeholder=" Password"
                  required
                  value={signuppassword}
                  onChange={(e) => {
                    setSignupPassword(e.target.value);
                  }}
                />
                {signuppassword &&
                  (signuppasswordShown ? (
                    <RiEyeOffLine
                      size={25}
                      className="eye-icon"
                      onClick={toggleSignupPassword}
                    />
                  ) : (
                    <HiOutlineEye
                      size={25}
                      className="eye-icon"
                      onClick={toggleSignupPassword}
                    />
                  ))}
              </div>
            </aside>
            <aside className="lg:w-[47%]">
              <p>Verify Password</p>
              <div class="password-input-container">
                <input
                  className="input__login w100 password-input"
                  type={confirmpasswordShown ? "" : "password"}
                  placeholder="confirm Password"
                  required
                  value={confirmpassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                {confirmpassword &&
                  (confirmpasswordShown ? (
                    <RiEyeOffLine
                      size={25}
                      className="eye-icon"
                      onClick={toggleConfirmPassword}
                    />
                  ) : (
                    <HiOutlineEye
                      size={25}
                      className="eye-icon"
                      onClick={toggleConfirmPassword}
                    />
                  ))}
              </div>{" "}
            </aside>
          </div>

          <section className="button__submit__section">
            <button
              className="bg-blue-950 text-white px-2 py-1"
              onClick={() => {
                handleSubmit();
              }}
            >
              Sign Up
            </button>
            <div className="forgot__password ">
              <p onClick={switchToSignIn} className="font__size__forgot">
                {" "}
                Log In
              </p>{" "}
              <p className="font__size__forgot"> |</p>{" "}
              <p className="font__size__forgot">Help ?</p>
            </div>
          </section>
        </div>
      </div>
      <aside>
        <picture>
          <img
            src={LoginPic}
            className="image__login"
            hight="400"
            width="600"
            alt="img"
          />
        </picture>
        <aside className="aside__got__to__site">
          <button
            onClick={() => {
              navigate(`/`);
            }}
            className="back__to__site"
          >
            Back To Site
          </button>
        </aside>
      </aside>
    </div>
  );
};

export default LoginForm;
