import React, { useState } from "react";
import POINT from "../Image/placeholder.png";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export default function SearchBox(props) {
  const { selectPosition, setSelectPosition } = props;
  const [searchText, setSearchText] = useState("");
  const [listPlace, setListPlace] = useState([]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <input
            style={{
              width: "100%", height: "35px", marginTop: "2px",
            }}
            type="text"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", padding: "0px 20px" }}
        >
          <button
            type="button"
            style={{
              backgroundColor: "darkblue",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
            }}
            onClick={() => {
              // Search
              const params = {
                q: searchText,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
              };
              const queryString = new URLSearchParams(params).toString();
              const requestOptions = {
                method: "GET",
                redirect: "follow",
              };
              fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {

                  setListPlace(JSON.parse(result));
                })
                .catch((err) => console.log("err: ", err));
            }}
          >
            Search
          </button>
        </div>
      </div>
      <div>
        <p>
          {listPlace.map((item) => (
            <p key={item?.place_id}>
              <div
                onClick={() => {
                  setSelectPosition(item);
                }}
              >
                <img
                  src={POINT}
                  alt="Placeholder"
                  style={{ width: 18, height: 18, margin: "10px 10px 0px 0px" }}
                />
                <span>{item?.display_name}</span>
              </div>
            </p>
          ))}
        </p>
      </div>
    </div>
  );
}
