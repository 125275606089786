import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./Pages/Login";
import ServiceBooking from "./Pages/Servicebooking";
import GarageRegister from "./Pages/GarageFromLogin/GarageRegister";
import Vehicleregistration from "./Pages/Vehicleregistration";
import Loading from "./Components/Loading";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Servicesdetials from "./Pages/Servicesdetails";
import Contact from "./Components/Contact";
import NewBranch from "./Pages/NewBranch";
import NewGarage from "../src/Pages/NewGarage";
import Dashboard from "./Pages/Dashboard";
import BranchRegistration from "./Pages/BranchRegistration";
import Listofgarages from "./Pages/Listofgarages";
import Garagedetails from "./Pages/Garagedetails";
import Map from "./Pages/Map";
import Geolocationnav from "./Pages/Geolocationnav";
import Specialization from "./Pages/Specialization";
import Addusers from "./Pages/Addusers";
import UserDashboard from "./Pages/UserDashboard";
import Garageusers from "./Pages/Garageusers";
import UserBranchRegistration from "./Pages/UserBranchRegistration";
import BookedServicesForGarage from "./Pages/BookedSevicesForGarage";
import Userservicedetails from "./Pages/Userservicedetails";
import Usergarageregistration from "./Pages/Usergarageregistration";
import Approvegarages from "./Pages/Approvegarages";
import Updatebookingstatus from "./Pages/Updatebookingstatus";
import LoginForm from "./Pages/LoginRegister/LoginForm";
import GaregeInformation from "./Pages/GaregeInformation";
import ServingBookingRedux from "./Pages/ServingBookingRedux";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/booking" element={<ServiceBooking />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route
            path="/GaregeInformation"
            element={<GaregeInformation />}
          />
          <Route
            path="/ServingBooking"
            element={<ServingBookingRedux />}
          />
          <Route
            path="/vehicleregistration"
            element={<Vehicleregistration />}
          />
          <Route
            path="/Updatebookingstatus"
            element={<Updatebookingstatus />}
          />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicesdetails" element={<Servicesdetials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Newbranch" element={<NewBranch />} />
          <Route path="/Listofgarage" element={<Listofgarages />} />
          <Route path="/garagedetails" element={<Garagedetails />} />
          <Route path="/Newgarage" element={<NewGarage />} />
          <Route path="/Branchregistration" element={<BranchRegistration />} />
          {/* <Route path="/Map" element={<Map />} /> */}
          <Route path="/Geolocationnav" element={<Geolocationnav />} />
          <Route path="/Specialization" element={<Specialization />} />
          <Route path="/Addusers" element={<Addusers />} />
          <Route path="/UserDashboard" element={<UserDashboard />} />
          <Route path="/Garageusers" element={<Garageusers />} />
          <Route
            path="UserBranchRegistration"
            element={<UserBranchRegistration />}
          />
          <Route path="bookedServices" element={<BookedServicesForGarage />} />
          <Route path="/Userservicedetails" element={<Userservicedetails />} />
          <Route
            path="/Usergarageregistration"
            element={<Usergarageregistration />}
          />
          <Route path="/GarageRegister" element={<GarageRegister />} />
          <Route path="/Approvegarages" element={<Approvegarages />} />
          <Route path="/LoginForm" element={<LoginForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
