import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

function Loading() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',

            }}
        >
            <PuffLoader
                className='Loaders'
                // size="20"
                // aria-label="Loading Spinner"
                // data-testid="loader"
                speedMultiplier="0.5"
                color=" #002D62"
            />

        </div>
    );
}

export default Loading;
