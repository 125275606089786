import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbaruser from "../Components/Navbaruser";
import { AiOutlineFolderView } from "react-icons/ai";
import { servicedetails } from "../Redux/Action/action";
import { FaSearch } from "react-icons/fa";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import moment from "moment"
function BookedServicesForGarage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterdata, setFilterdata] = useState("");
  const [startdate, setstartdate] = useState(" ");
  const [endDate, setendDate] = useState("");
  const selector = useSelector((state) => state.LoginReduceruser);
  const garageName = selector.garageName;

  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  async function getData() {
    setLoading(true)
    const response = await axios.post(

      `${BASEURL}/getServiceListforGarage`,
      {
        garageName: garageName,
      },
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );

    setBookingList(response.data.recordset);
    setLoading(false);

  }

  useEffect(() => {
    getData();
  }, []);
  const filtereddata = bookingList.filter((item) => {
    const searchText = filterdata.toLowerCase().split(" ");

    const matchesAllWords = searchText.every((part) => {
      const foundInCompany = item.BOOKEDINCOMPANY.toLowerCase().includes(part);
      const foundInComponent =
        item.MODEL.toLowerCase().includes(part);
      const foundInBranch = item.BRANCH.toLowerCase().includes(part);
      const foundInBookingId = item.MAKE.toLowerCase().includes(part);
      const foundInRegistration = item.ASSETREGISTRATION
        ? item.ASSETREGISTRATION.toLowerCase() === part
        : false;
      return (
        foundInCompany ||
        foundInComponent ||
        foundInBranch ||
        foundInBookingId ||
        foundInRegistration
      );
    });

    const startDateFilter =
      (startdate && endDate === "") ||
      (startdate &&
        endDate &&
        item.BOOKINGDATE >= startdate &&
        item.BOOKINGDATE <= endDate) ||
      (endDate && startdate === "" && item.BOOKINGDATE <= endDate) ||
      startdate === item.BOOKINGDATE;

    return matchesAllWords && startDateFilter;
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbaruser />
      <header className="header__of__main" style={{ margin: "10px" }}>
        <h1 className="header__of__page"> Services</h1>
      </header>

      <div className="service___detail__filter">
        <div style={{ display: "flex" }}>
          <label>Search : </label>
          <input
            className="filtereddata"
            type="text"
            name="filter"
            onChange={(e) => setFilterdata(e.target.value)}
          />
          <div className="search__icon__service">
            <FaSearch />
          </div>
        </div>
        <div>
          <label>Start Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setstartdate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date :</label>
          <input
            className="filter_dates"
            type="date"
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1">
          <thead >
            <tr>
              <th style={{ textAlign: "right", paddingRight: "30px" }}> BOOKING DATE</th>
              <th>BRANCH</th>
              <th>MAKE</th>
              <th>MODEL</th>
              <th>ASSET REGISTRATION</th>
              <th style={{ paddingRight: "30px", width: "210px" }}>TO SERVICE DATE</th>

              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {filtereddata.length === 0 ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center", color: "red" }}>
                  No Services
                </td>
              </tr>
            ) : (
              filtereddata.map((item) => (
                <tr>
                  <td style={{ textAlign: "right", paddingRight: "30px" }}>{item.BOOKINGDATE ? moment(item.BOOKINGDATE).format("DD-MM-YYYY") : "N/A"}</td>
                  <td>{item.BRANCH ? item.BRANCH : "N/A"}</td>
                  <td>{item.MAKE ? item.MAKE : "N/A"}</td>
                  <td>{item.MODEL ? item.MODEL : "N/A"}</td>
                  <td>{item.ASSETREGISTRATION ? item.ASSETREGISTRATION : "N/A"}</td>
                  <td style={{ textAlign: "right", paddingRight: "90px" }}>{item.TOSERVICEDATE ? moment(item.TOSERVICEDATE).format("DD-MM-YYYY") : "N/A"}</td>

                  <td onClick={() => { dispatch(servicedetails(item)); navigate("/Userservicedetails") }}><span
                    className="icon__for__forms"
                    style={{
                      fontSize: "28px",
                      width: "32px",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    <AiOutlineFolderView />{" "}
                  </span></td>
                </tr>
              )))}
          </tbody>

        </table>
      </div>
      <Footer />
    </div>

  )

}
export default BookedServicesForGarage;
