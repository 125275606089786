import React from 'react';
import "../Style/Home.css";
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <div className='contact_us'>
            <div >
                <h3 className='contact'>Contact Your Administration</h3>
                <Link className='login_btn' to="/">Home</Link>
                {/* <Link className='buttons' to="/services">Services</Link> */}
            </div>
        </div>
    )
}

export default Contact