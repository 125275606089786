import React from "react";
import { Link } from "react-router-dom";
import ServiceBooking from "./Servicebooking";
import Vehicleregistration from "./Vehicleregistration";
import "../Style/Home.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import axios from "axios";
import Navbar from "../Components/Navbar";
import img1 from "../Image/garage.jpg";
import car1 from "../Image/car1.svg";
import car2 from "../Image/car2.svg";
import car3 from "../Image/car3.svg";
import Footer from "../Components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./styles.css";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Grid,
  Autoplay,
} from "swiper/modules";
import { garagedetails } from "../Redux/Action/action";
import { useSelector } from "react-redux";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Loading from "../Components/Loading";
function Home() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const selector = useSelector((state) => state.LoginReduceruser);
  const [isloading, setIsloading] = useState(true);
  const [Bannerlist, setBannerlist] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(fetchdata, []);
  function fetchdata() {
    setIsloading(true);

    axios
      .get(
        `${BASEURL}/getAllGarageDetails`,

        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        setBannerlist(response.data);
      })
      .catch((err) => {
        console.log(err);

        setIsloading(false);
        // navigate("/");
      });
  }



  if (isloading) {
    return <Loading />;
  }
  return (
    <div className="bg-slate-100">
      <Navbar />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page">
          {" "}
          Welcome to MarsTrack Service Booking
        </h1>
      </header>
      <div className="w-full">
        <div className="px-[10px] my-[10px] w-full uppercase">
          <Swiper
            spaceBetween={30}
            centeredSlides={false}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 6,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {Bannerlist.slice()
              .reverse()
              .map((item, index) => (
                <SwiperSlide key={index} onClick={() => {
                  dispatch(garagedetails(item));
                  navigate("/garegeInformation");
                }}>
                  <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer text-left h-[400px] ">
                    <img
                      className="w-[400px] h-[200px] object-cover"
                      src={item?.["MAINPAGEIMAGE"]}
                      alt="GALLERY"
                    />
                    <p className="text-[12px] font-bold text-blue-900 px-2">
                      {" "}
                      GARAGE NAME{" "}
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {" "}
                      {item["GARAGENAME"]}
                    </p>
                    <p className="text-[12px] font-bold px-2 text-blue-900">
                      LOCATION
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {item["GARAGECITY"]} , {item["GARAGECOUNTRY"]}
                    </p>
                    <p className="text-[12px] font-bold px-2 text-blue-900">
                      SPECIALIZATION
                    </p>
                    <p className="text-[14px] font-bold px-2">
                      {item["GARAGESPECIALIZATION"]}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
      <section className="flex items-center justify-center gap-10 my-10 flex-wrap">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/Vehicleregistration">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car1}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Vehicle Registration
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/booking">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car2}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Service Booking
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[300px]">
          <Link to="/services">
            {" "}
            <img
              className="w-[300px] h-[150px] object-contain"
              src={car3}
              alt="Card Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                Services
              </h2>
            </div>
          </Link>
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../Style/Home.css'; // Create a separate CSS file for styling

// const Home = () => {
//     return (
//         <div className='navbar'>
//             <div className='btn_link'>
//                 <Link className='buttons' to="/booking">Service Booking</Link>
//                 <Link className='buttons' to="/Vehicleregistration">Vehicle Registration</Link>
//             </div>
//         </div>
//     );
// };

// export default Home;
