const initialState = {}

function vehiclereducer(state = initialState, action) {
    switch (action.type) {
        case 'VEHICLE':
            return action.data
        default:
            return state
    }
}
export default vehiclereducer