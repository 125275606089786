import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar2 from "../Components/Navbar2";
import { MdPermIdentity } from "react-icons/md";
import { IoMdCreate } from "react-icons/io";
import { GrServices } from "react-icons/gr";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai"
import "../Style/Specialization.css";
function Specialization() {
  const Navigate = useNavigate();
  const selector = useSelector((state) => state.LoginReduceruser);

  const [serviceId, setserviceId] = useState("");
  const [servicename, setservicename] = useState("");
  const [allservicedetails, setallservicedetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function showExpiryAlertOld() {
    let alertMessage = "Service Name is mandatory.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Please select geolocation.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  function getserviceId() {
    setLoading(true);
    axios.get(`${BASEURL}/getgarageServiceId`, {
      headers: {
        "auth-token": authToken,
      },
    }).then((response) => {
      setserviceId(response.data.recordset[0].ID);

      setLoading(false);

      axios.get(`${BASEURL}/getServicesList`, {
        headers: {
          "auth-token": authToken,
        },
      }).then((response) => {
        setallservicedetails(response.data.recordset);

      });
    });
  }
  function Editservice(item) {
    setservicename(item.SERVICENAME);
    setserviceId(item.SERVICEID);
    // setservicename(selector.email.split("@")[0])
  }

  useEffect(getserviceId, []);

  function SaveService() {
    if (!servicename) {
      // alert("Service Name is mandatory.");
      showExpiryAlertOld()
      return;
    }
    const confirmed = window.confirm("Are you sure you want to Save?");
    if (confirmed) {
      setLoading(true);

      axios
        .post(`${BASEURL}/insertService`, {
          // SERVICEID: serviceId,
          SERVICECREATEDBY: selector.email,
          SERVICENAME: servicename,
        }, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {

          alert(response.data.message);
          window.location.reload();
          setLoading(false);
        })
        .catch((err) => {

          alert(err.response.data.message);
          setLoading(false);
          // Navigate("/")
        });
    } else {
      setLoading(false);
    }
  }


  const filteredData = allservicedetails.filter((item) => {

    if (servicename === "") {
      return item;
    } else if (item.SERVICENAME.toLowerCase().includes(servicename.toLowerCase())) {
      return item;
    }
    // return servicename.includes(item.SERVICENAME);

  });


  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  filteredData.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.SERVICEID - b.SERVICEID;
    } else {
      return b.SERVICEID - a.SERVICEID;
    }
  });

  useEffect(toggleSortOrder, [])




  const scrollToSection = () => {
    window.scrollTo({
      top: 0, // Change this to the desired position to scroll to
      behavior: "smooth", // Apply the smooth scrolling behavior
    });
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar2 />
      {showAlert && <div className="overlay"></div>}
      <div style={{ zIndex: 2000 }}>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms"
                style={{
                  fontSize: "25px", width: "20px", textAlign: "RIGHT", position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                <AiOutlineClose />{" "}
              </span>


            </p>

          </div>
        )}
      </div>
      <p
        style={{
          padding: "24px",
          borderRadius: "4px",
        }}
      >
        <header className="header__of__main">
          <h1>SPECIALIZATION</h1>
        </header>
        <div className="backgroundcolor">
          <div className="specialization"></div>
          <div className="input_fields_spl">
            <aside>
              {/* <p className="service_info2">
                <span
                  className="icon__for__forms"
                  style={{ fontSize: "18px", width: "28px" }}
                >
                  <MdPermIdentity />{" "}
                </span>
                <label>Service ID :</label>
                <span style={{ color: "red" }}>{serviceId}</span>
              </p> */}
              <p className="service_info2">
                <span
                  className="icon__for__forms"
                  style={{ fontSize: "18px", width: "17px" }}
                >
                  <IoMdCreate />{" "}
                </span>
                <label>Created By : </label>
                <span style={{ color: "red", textTransform: "uppercase", marginLeft: "4px" }}>
                  {selector.email.split("@")[0]}
                </span>
              </p>
            </aside>
            <aside>
              <p className="service_info3">
                <span
                  className="icon__for__forms"
                  style={{ fontSize: "18px", width: "30px" }}
                >
                  <GrServices />{" "}
                </span>
                <label className="service_name_input">Service Name : </label>
                <input
                  required
                  name="SERVICENAME"
                  type="text"
                  value={servicename}
                  onChange={(e) => setservicename(e.target.value)}
                ></input>
              </p>
            </aside>
          </div>
          <span className="technical_btn">
            <button
              className="Save_spl"
              type="button"
              onClick={(e) => SaveService()}
            >
              Save
            </button>
          </span>
        </div>
      </p>
      <div className="search__for__list">
        <p>Search :&nbsp;</p>
        <input onChange={(e) => (setservicename(e.target.value))}></input>
        <div className="search__icon">
          <FaSearch />
        </div>
      </div>
      <div className="service_detials_spl">
        <table className="table_1_spl">

          <thead>
            <tr>
              <th style={{ textAlign: "center" }}><div onClick={toggleSortOrder}>
                {sortOrder === "asc" ? "▲" : "▼"} SERVICE ID
              </div></th>
              <th>Service Name</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "67px",
                  }}
                >
                  {item.SERVICEID}
                </td>
                <td>{item.SERVICENAME}</td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={(e) => {
                    Editservice(item);
                    scrollToSection();
                  }}
                >
                  {" "}
                  <BiSolidEditAlt />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Specialization;
