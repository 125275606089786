import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import Navbar2 from '../Components/Navbar2'
import "../Style/AddUsers.css"
import axios from 'axios';
import Select from "react-select";
import Loading from '../Components/Loading';
import Footer from '../Components/Footer';
import { BsCalendarDate } from "react-icons/bs";
import { BiUserPin } from "react-icons/bi";
import { MdOutlineAlternateEmail, MdDriveFileRenameOutline, MdPassword } from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { GoNumber } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux";


function Addusers() {
    const intialvalues = {
        GARAGEID: '',
        GARAGEUSERNAME: '',
        GARAGEUSERPASSWORD: '',
        GARAGEUSEREMAIL: '',
        GARAGEUSERECREATEDDATE: '',
        GARAGEUSERECREATEDBY: '',
    }
    const [show, setShow] = useState(false);
    const [details, setDetials] = useState(intialvalues)
    const [garages, setgarages] = useState([])
    const [selectedOption, setSelectedOption] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEmailValid, setEmailError] = useState(true);
    const [isValidationError, setIsValidationError] = useState(false);

    // const [Error, setError] = useState("");
    const errRef = useRef();
    const BASEURL = process.env.REACT_APP_BASEURL;
    const authToken = window.sessionStorage.getItem("auth-token");


    const [test, customTest] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    function showExpiryAlertOld() {
        let alertMessage = "Please fill in all required fields.";
        customTest(alertMessage); // Display the alert message
        setShowAlert(true);
    }
    function geolocationAlert() {
        let alertMessage = "Please select geolocation.";
        customTest(alertMessage); // Display the alert message
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    };
    function GaregeDetails() {
        setLoading(true)
        axios.get(`${BASEURL}/getgarages`, {
            headers: {
                "auth-token": authToken,
            },
        }).then((response) => {
            setgarages(response.data.recordsets[0])

            setLoading(false)
        }).catch(() => {
            alert("Internal server error! please try after some time");
        });
    }
    useEffect(GaregeDetails, [])
    function handleinputs(e) {
        const { name, value } = e.target
        setDetials({ ...details, [name]: value })

        if (name === "GARAGEUSEREMAIL") {
            const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const isValid = emailRegex.test(value);

            // Clear the error message and validation error flag if the email is valid
            if (isValid) {
                setEmailError("");
                setIsValidationError(false);
            }
        }
        // if (name === 'GARAGEUSEREMAIL') {
        //     const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        //     const isValid = emailRegex.test(value);
        //     setIsEmailValid(isValid);
        //     setError(isValid ? "" : "Enter a valid email!");
        // }
    }
    function SaveUser() {

        const inputEmail = details.GARAGEUSEREMAIL;

        const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValid = emailRegex.test(inputEmail);

        if (!isValid) {
            setEmailError("Enter a valid email!");
            setIsValidationError(true);
            return;

        }
        else {
            // setEmailError("");
            // setIsValidationError(false);
            if (!details.GARAGEID ||
                !details.GARAGEUSERPASSWORD ||
                !details.GARAGEUSEREMAIL ||
                !details.GARAGEUSERECREATEDBY) {
                showExpiryAlertOld()
                return
            }

            const confirm = window.confirm("Are you sure you want to Save?");
            if (confirm) {
                setLoading(true)
                axios.post(`${BASEURL}/insertGarageUser`, details,
                    {
                        headers: {
                            "auth-token": authToken,
                        },
                    }).then((response) => {
                        alert(response.data.message);
                        setLoading(false)
                        window.location.reload();
                    }).catch(() => {
                        alert("Internal server error! please try after some time");
                    });
            }
        }
    }

    function handlepassword() {
        setShow(!show)
    }
    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            <Navbar2 />
            {showAlert && <div className="overlay"></div>}
            <div>
                {showAlert && (
                    <div className="custom-alert  animation__Style">
                        <p className="align__button">
                            <p>{test}</p>
                            <span
                                className="icon__for__forms"
                                style={{
                                    fontSize: "25px", width: "20px", textAlign: "RIGHT", position: "relative",
                                    top: "-17px",
                                    marginLeft: "15px",
                                    color: "red",
                                    cursor: "pointer",
                                }}
                                onClick={handleCloseAlert}>
                                <AiOutlineClose />{" "}
                            </span>


                        </p>
                    </div>
                )}
            </div>
            <div >
                <div className="vehicle__registration">
                    <form className="forM__style__vehicle__reg_">
                        <header
                            className="header__of__main"
                            style={{ margin: "0px", marginLeft: "0px" }}
                        >
                            <h1
                                className="header__of__page"
                                style={{ margin: "0px", margin: "0px 0px 10px 0px" }}
                            >
                                {" "}
                                User Registration
                            </h1>
                        </header>

                        <div className="section">
                            <div className="input__group">
                                <p>
                                    <span className="icon__for__forms">
                                        <GiHomeGarage />
                                    </span>
                                    GARAGE NAME :  &nbsp;
                                </p>
                                <p>
                                    <Select
                                        name="GARAGENAME"

                                        onChange={(e) => {
                                            setDetials((prevDetails) => ({
                                                ...prevDetails,
                                                GARAGEID: e.value,
                                            }))
                                            setSelectedOption(e.value)
                                        }
                                        }

                                        options={garages.map((item) => ({
                                            label: item.GARAGENAME,
                                            value: item.GARAGENUMBER,
                                        }))}

                                    />
                                </p>
                            </div>
                            <div className="input__group">
                                <p className='garageid_'>
                                    <span className="icon__for__forms">
                                        <GoNumber />
                                    </span>
                                    <p>GARAGE ID :  </p> &nbsp;<p style={{ color: "red" }}>{selectedOption}</p>
                                </p>
                                {/* <p className="created_by">
                                    <span style={{ color: "red" }}>{selectedOption}</span>
                                </p> */}
                            </div>
                            <div className="input__group">
                                <p>
                                    <span className="icon__for__forms">
                                        <MdDriveFileRenameOutline />
                                    </span>
                                    USER NAME :  &nbsp;
                                </p>

                                <input
                                    name='GARAGEUSERNAME'
                                    type='text'
                                    onChange={(e) => (handleinputs(e))}
                                />

                            </div>
                            <div className="input__group">
                                <p>
                                    <span className="icon__for__forms">
                                        <MdDriveFileRenameOutline />
                                    </span>
                                    USER CREATED BY :  &nbsp;
                                </p>

                                <input
                                    name='GARAGEUSERECREATEDBY'
                                    type='text'
                                    onChange={(e) => (handleinputs(e))}
                                />

                            </div>

                            <div className="input__group">
                                <p>
                                    <span className="icon__for__forms">
                                        <MdOutlineAlternateEmail />
                                    </span>
                                    USER EMAIL :  &nbsp;
                                </p>

                                <input
                                    name='GARAGEUSEREMAIL'
                                    type='email'

                                    onChange={(e) => (handleinputs(e))}
                                />
                                {isValidationError && <p className="errmsg" aria-live="assertive">{isEmailValid}</p>}
                            </div>


                            <div className="input__group">
                                <p>
                                    <span className="icon__for__forms">
                                        <MdPassword />
                                    </span>
                                    USER PASSWORD :  &nbsp;
                                </p>

                                <input
                                    name='GARAGEUSERPASSWORD'
                                    type={show ? "text" : "password"}

                                    onChange={(e) => (handleinputs(e))}
                                /><span className='icon-show-pass' onClick={handlepassword}><AiOutlineEye /></span>

                            </div>
                        </div>
                        <div style={{ padding: "10px" }}>
                            <div className="btn__Submit__reg">
                                <p>
                                    <button
                                        onClick={() => window.location.reload()}
                                        className="button"
                                    >
                                        Clear
                                    </button>
                                </p>
                                <p>
                                    <button type="button" className="button" onClick={(e) => SaveUser(e)}> Save</button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div >

            <div>
                <Footer />
            </div>
        </div >
    )
}

export default Addusers