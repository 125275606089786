import React from "react";
import "./Footer.css";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  FaSortAmountDownAlt,
  FaSortAmountUpAlt,
  FaRegArrowAltCircleUp,
} from "react-icons/fa";
const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyDetails, setcompanyDetails] = useState([]);
  const stateCompany = useSelector((state) => state.SelCompany);
  const selCompany = stateCompany?.selectedCompany;
  console.log(selCompany);
  const authToken = window.sessionStorage.getItem("auth-token");
  console.log(authToken);
  // year has been added
  const today = new Date();
  const year = today.getFullYear();

  const handleEmailClick = () => {
    // Replace 'mailto:' with the email address
    window.location.href = "mailto:Support@tech23.net";
  };

  const handlePhoneClick = () => {
    // Replace 'tel:' with the phone number
    window.location.href = "tel:+254798074411";
  };

  return (
    <div>
      <footer id="Support" className="footerstyle bg-[#3d3c3c] ">
        <div className="toalignaside">
          {/* brand name is given in H tag */}

          <aside className="aside1 cursor-pointer">
            <h3 className="font-bold">TeCH23</h3>
            {/* email of the company  */}
            <p onClick={handleEmailClick}>Email : Support@tech23.net</p>
            <p onClick={handlePhoneClick}>Contact : +254 798074411</p>
            {/* contact of company is given here */}
          </aside>

          <aside className="aside2">
            {/* detail information about terms of use  */}
            <p>
              <h4 className="font-bold">Terms of Use</h4>
              This Software program is protected by the copyright law and
              international treaties.Unauthorized reproduction or redistribution
              of this program or any portion of it may result in severe civil
              and penalties, and will be prosecuted to maximum extent possible
              under the law.
            </p>
          </aside>
        </div>
        {/* copyright with symbol and all right reserved */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", textAlign: "center" }}
        >
          {/* year has been added dynamically */}
          Copyright © {year} TeCH23. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
