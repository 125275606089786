import React from "react";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../Style/Servicebooking.css";
import Loading from "../Components/Loading";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { Country, City } from "country-state-city";
import CreatableSelect from "react-select/creatable";
import { IoMdTime } from "react-icons/io";
import {
  BiSolidUserAccount,
  BiUserPin,
  BiSolidSend,
  BiCategory,
} from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import {
  AiOutlineFieldNumber,
  AiOutlinePhone,
  AiOutlineClose,
} from "react-icons/ai";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { BsTruck } from "react-icons/bs";
import { PiEngine } from "react-icons/pi";
import { BsPersonFillGear, BsPersonLock } from "react-icons/bs";
import { FaCity } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GrNotes, GrFormClose } from "react-icons/gr";
import { BiSolidAddToQueue } from "react-icons/bi";

function ServiceBooking() {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.LoginReduceruser);

  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [value, setValue] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const garageredux = useSelector((state) => state.garagereducer);
  console.log(garageredux);
  console.log(garageredux?.GARAGENAME)
  var clientCode = selector.clientCode;
  const initialValue = {
    BOOKINGID: "",
    CLIENTCODE: selector.clientCode,
    BOOKINGDATE: "",
    VEHICLECOMPONENT: garageredux?.GARAGESPECIALIZATION ? garageredux?.GARAGESPECIALIZATION : "",
    REGISTRATIONNO: 0,
    MAKE: "",
    MODEL: "",
    TOBESERVICEDON: "",
    PROBLEMDESCRIPTION: "",
    TOBESENTTOCOMPANY: "",
    TOBESENTTOCOMPANYBRANCH: "",
    REPORTEDBYINYOURCOMPANY: "",
    PROCESSEDBYMOBILENO: "",
    PROCESSEDBY: selector.emailId,
    PROCESSEDDATE: "",
    NEWREGSTATE: false,
    TOBESENTTOCOMPANYID: "",
    BOOKINGTIMEPREFERED: ""
  };
  const [newvehicleregstate, setnewvehicleregstate] = useState(false);
  const [userdetials, setUserDetials] = useState(initialValue);
  const [currentDate, setCurrentDate] = useState("");
  const [vehregistration, setvehregistration] = useState([]);
  const [togarage, setTogarage] = useState([]);
  const [garage, setgarage] = useState("");
  const [garagebranch, setgaragebranch] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [city, setcity] = useState([]);
  const [City, setCity] = useState(null);
  const [selectedCity, setselectedcity] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cities, setCities] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);

  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function showExpiryAlertOld() {
    let alertMessage = "Please fill in all required fields.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Service due date must be greater than booking date";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const countries = Country.getAllCountries();
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;
      const selectedCountryObject =
        Country.getCountryByCode(selectedCountryIso);

      const phoneCode = selectedCountryObject.phonecode || "";

      setUserDetials((prevDetails) => ({
        ...prevDetails,
        PROCESSEDBYMOBILENO: `+${phoneCode}`,
      }));
    }
  };
  function handleInputs(e) {
    const { name, value } = e.target;
    setUserDetials({ ...userdetials, [name]: value });
  }

  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  function validateDate() {
    // var currentDate = new Date();

    var userDateInput = document.getElementById("userDate")?.value;

    var userDateInput2 = document.getElementById("userDate2")?.value;

    // if (!userDateInput || !currentDate || !userDateInput2) {
    //   alert("Please fill in all date fields");
    //   return;
    // }
    var joining_date = new Date(userDateInput);
    var service_due_date = new Date(userDateInput2);
    var proceeddate = new Date(currentDate);

    if (service_due_date < joining_date) {
      geolocationAlert();
      setUserDetials({
        ...userdetials,

        TOBESERVICEDON: "MM/DD/YYYY",
      });

      document.getElementById("userDate2").value = "";
      return;
    }
    setUserDetials({
      ...userdetials,
      BOOKINGDATE: userDateInput,
      PROCESSEDDATE: currentDate,
      TOBESERVICEDON: userDateInput2,
    });
  }
  function fetchdata() {
    setIsloading(true);
    axios
      .post(
        `${BASEURL}/getclientvehicle`,
        { client: clientCode },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsloading(true);
        console.log(response.data.recordset)
        setvehregistration(response.data.recordset);

        axios
          .get(`${BASEURL}/getgarages`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            // setTogarage(response.data.recordsets[0]);

            setIsloading(true);
            axios
              .get(`${BASEURL}/getnewbookingNumber`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setBookingId(response.data);

                initialValue.BOOKINGID = response.data.BOOKINGID;
                setIsloading(false);
              });
          });
      })
      .catch((err) => {
        console.log(err)
        setIsloading(false);
        // navigate("/");
      });

    axios
      .get(`${BASEURL}/getServicesList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        const serviceList = response.data.recordset;

        if (garageredux?.GARAGESPECIALIZATION) {
          const selectedService = serviceList.find(
            (item) => item.SERVICENAME === garageredux.GARAGESPECIALIZATION
          );
          console.log(selectedService)
          if (selectedService) {
            const serviceId = selectedService.SERVICEID;
            console.log("Service ID:", serviceId); // You can use the serviceId as needed
            // Call other functions or set state based on serviceId
            getGarageList(serviceId); // If you want to call getGarageList with the serviceId
          } else {
            console.log("No matching service found.");
          }
        } else {
          setServiceList(serviceList); // Set full service list if specialization is not present
        }
      })
      .catch((err) => {
        console.log(err)
        setIsloading(false);
        // navigate("/");
      });
  }

  useEffect(fetchdata, []);
  // let City = document.getElementsByName("city")
  function getgaragebranch(selectedValue) {
    setgarage(selectedValue);
    axios
      .post(
        `${BASEURL}/getgaragebranch`,
        { garage: selectedValue },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setgaragebranch(response.data.recordsets[0]);
      })
      .catch((err) => {
        console.log(err)
        setIsloading(false);
        // navigate("/");
      });
  }

  function getcity() {
    axios.get(`${BASEURL}/getbranchcitylist`, {
      headers: {
        "auth-token": authToken,
      },
    }).then(
      (response) => {
        setcity(response.data.recordsets[0]);
      },

    ).catch((err) => {
      console.log(err)
    })
  }
  // useEffect(getcity, []);
  useEffect(() => {
    getcity()
  }, [])

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minWidth: "300px",
      minHeight: "initial",
      color: "black",
      height: "38px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  function formatMobileNumber(event) {
    const input = event.target;
    const unformattedNumber = input.value.replace(/[^\d+\- ]/g, "");

    if (unformattedNumber.length <= 15) {
      input.value = unformattedNumber;
    } else {
      const formattedNumber = unformattedNumber.replace(
        /(\d{3})(\d{4})(\d{4})(\d+)/,
        "$1-$2-$3-$4"
      );
      input.value = formattedNumber;
    }
  }
  // useEffect(() => {
  //   if (garageredux?.GARAGESPECIALIZATION) {
  //     const selectedService = serviceList.find(
  //       (item) => item.SERVICENAME === garageredux?.GARAGESPECIALIZATION
  //     );

  //     if (selectedService) {
  //       getGarageList(selectedService.SERVICEID);
  //       console.log(selectedService.SERVICEID) // Call the function with the SERVICEID
  //     }
  //   }
  // }, [garageredux?.GARAGESPECIALIZATION, serviceList]);

  function getGarageList(data) {
    console.log("Service ID being sent:", data); // Debugging log
    console.log("Auth token:", authToken); // Debugging log

    axios
      .post(
        `${BASEURL}/getGarageListbySpec`,
        { serviceId: data },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data); // Debugging log
        setTogarage(response.data.recordset);
      })
      .catch((error) => {
        console.error("Error:", error); // Log the actual error

        setIsloading(false);
      });
  }
  async function handleSubmit(e) {
    console.log("BOOKINGDATE:", userdetials.BOOKINGDATE);
    console.log("PROBLEMDESCRIPTION:", userdetials.PROBLEMDESCRIPTION);
    console.log("PROCESSEDBY:", userdetials.PROCESSEDBY);
    console.log("PROCESSEDBYMOBILENO:", userdetials.PROCESSEDBYMOBILENO);
    console.log("PROCESSEDBYMOBILENO Length > 5:", userdetials.PROCESSEDBYMOBILENO.length > 5);
    console.log("TOBESENTTOCOMPANY:", userdetials.TOBESENTTOCOMPANY);
    console.log("PROCESSEDDATE:", userdetials.PROCESSEDDATE);
    console.log("REPORTEDBYINYOURCOMPANY:", userdetials.REPORTEDBYINYOURCOMPANY);
    console.log("REGISTRATIONNO:", userdetials.REGISTRATIONNO);
    if (
      !userdetials.BOOKINGDATE ||
      !userdetials.PROBLEMDESCRIPTION ||
      !userdetials.PROCESSEDBY ||
      !userdetials.PROCESSEDBYMOBILENO.length > 5 ||
      !userdetials.TOBESENTTOCOMPANY ||
      !userdetials.PROCESSEDDATE ||
      !userdetials.REPORTEDBYINYOURCOMPANY ||
      !userdetials.REGISTRATIONNO
    ) {
      showExpiryAlertOld();
      return;
    }
    e.preventDefault();
    userdetials.MAKE = make;
    userdetials.MODEL = model;

    userdetials.PROCESSEDDATE = currentDate;
    const confirm = window.confirm("Are you sure you want to Book?");
    if (confirm) {
      setIsloading(true);
      const response = await axios
        .post(`${BASEURL}/servicebook`, userdetials, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            alert(response.data.message);
            // Setloading(false)
            setIsloading(false);

            window.location.reload();
          } else {
            alert("An error occured!");
            window.location.reload();
            // Setloading(false)
            setIsloading(false);
          }
        })
        .catch((err) => {
          alert(err.response.data.message);
          window.location.reload();
        });
    } else {
      // window.location.reload();
      setIsloading(false);
    }
  }
  const handleTimeChange = (value) => {
    const minTime = "09:00";
    const maxTime = "16:00";

    // Check if the selected time is within the allowed range
    if (value >= minTime && value <= maxTime) {
      setUserDetials({
        ...userdetials,
        BOOKINGTIMEPREFERED: value,
      });
    } else {
      alert("Please select a time between 9:00 AM and 4:00 PM.");
      // Reset the time value if it's outside the allowed range
      setUserDetials({
        ...userdetials,
        BOOKINGTIMEPREFERED: "",
      });

      return
    }

  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar />
      {showAlert && <div className="overlay"></div>}
      <div style={{ zIndex: 2000 }}>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms"
                style={{
                  fontSize: "25px",
                  width: "20px",
                  textAlign: "RIGHT",
                  position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                <AiOutlineClose />{" "}
              </span>
            </p>
          </div>
        )}
      </div>
      {/* {garageredux?.GARAGENAME && <div className="p-2 flex justify-end" >
        <button className="text-white bg-blue-700 p-2" onClick={() => navigate("/garegeInformation")}>BACK</button>
      </div>} */}
      <div className="form-container">
        <section className="form-wrapper">
          <header
            className="header__of__main"
            style={{ margin: "0px", marginLeft: "0px" }}
          >
            <h1
              className="header__of__page"
              style={{ margin: "0px", margin: "20px" }}
            >
              {" "}
              Service Booking
            </h1>

          </header>

          <table className="servicebooking">
            <div className="customer_details">
              <div>
                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Booking ID :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {bookingId.BOOKINGID}
                  </span>
                </tr>

                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <BsPersonLock />{" "}
                    </span>{" "}
                    Customer Id :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {selector.clientCode}
                  </span>
                </tr>
                <tr className="table_row__first">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <BsPersonFillGear />{" "}
                    </span>{" "}
                    Processed By :
                  </p>
                  <span style={{ color: "red", fontSize: "18px" }}>
                    {selector.emailId ? selector.emailId.split("@")[0] : "N/A"}
                  </span>
                </tr>
              </div>

              <div>
                <tr className="table_row__first2">
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Vehicle Registration :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      // isClearable
                      style={{ color: "black" }}
                      required
                      value={newvehicleregstate ? "" : selectedRegistration}
                      styles={customStyles}
                      name="REGISTRATIONNO"
                      noOptionsMessage={() => (
                        <button
                          className="add_registration"
                          style={{ width: "170px", marginTop: "10px" }}
                          onClick={async (e) => {
                            // optionExists
                            // options(hide)
                            setnewvehicleregstate(true);
                            userdetials.NEWREGSTATE = true;
                            const makeResponse = await axios.get(
                              `${BASEURL}/getmakeList`
                            );

                            setMakeList(makeResponse.data.recordset);
                            const modelResponse = await axios.get(
                              `${BASEURL}/getmodelList`
                            );

                            setModelList(modelResponse.data.recordset);
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: "20px",
                              position: "relative",
                              top: "3px",
                            }}
                          >
                            <BiSolidAddToQueue />
                          </span>{" "}
                          Register Vehicle
                        </button>
                      )}
                      onChange={(e) => {
                        setUserDetials({
                          ...userdetials,
                          REGISTRATIONNO: e.value,
                        });
                        // testing//
                        const optionExists = vehregistration.some(
                          (item) => item.VEHICLEREGNO === e.value
                        );

                        if (optionExists) {
                          setSelectedRegistration(e);
                        }
                        // else {
                        //   setSelectedRegistration("");
                        // }
                        //***//

                        axios
                          .post(
                            `${BASEURL}/getvehicleDetails`,
                            {
                              registration_no: e.value,
                            },
                            {
                              headers: {
                                "auth-token": authToken,
                              },
                            }
                          )
                          .then((response) => {
                            // setIsloading(true);

                            if (response.data.recordset) {
                              setMake(response.data.recordset[0].VEHICLEMAKE);
                              setModel(response.data.recordset[0].VEHICLEMODEL);
                              userdetials.MAKE =
                                response.data.recordset[0].VEHICLEMAKE;
                              userdetials.MODEL =
                                response.data.recordset[0].VEHICLEMODEL;
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch((err) => {
                            alert(err.response.data.message);
                            setIsloading(false);
                            // navigate("/");
                          });
                      }}
                      options={vehregistration.map((item) => ({
                        label: item.VEHICLEREGNO,
                        value: item.VEHICLEREGNO,
                      }))}
                    />
                  </div>
                </tr>
                <div
                  className="direct_vehicle_reg"
                  hidden={!newvehicleregstate}
                >
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Enter Registration No :{" "}
                  </p>
                  <input
                    type="text"
                    onChange={(e) => {
                      userdetials.REGISTRATIONNO = e.target.value;
                    }}
                  />
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Make :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      className="select_model"
                      style={{ color: "black" }}
                      required
                      placeholder="Select..."
                      styles={customStyles}
                      onChange={(e) => {
                        // userdetials.MAKE = e.value;
                        setMake(e.value);
                      }}
                      options={makeList.map((item) => ({
                        label: item.VEHICLEMAKE,
                        value: item.VEHICLEMAKE,
                      }))}
                    />
                  </div>
                  <p>
                    {" "}
                    <span
                      className="icon__for__forms"
                      style={{ fontSize: "18px", width: "20px" }}
                    >
                      <MdOutlineConfirmationNumber />{" "}
                    </span>{" "}
                    Model :{" "}
                  </p>
                  <div className="select__align">
                    <Select
                      className="select_model"
                      style={{ color: "black" }}
                      required
                      placeholder="Select..."
                      styles={customStyles}
                      onChange={(e) => {
                        // userdetials.MODEL = e.value;
                        setModel(e.value);
                      }}
                      options={modelList.map((item) => ({
                        label: item.VEHICLEMODEL,
                        value: item.VEHICLEMODEL,
                      }))}
                    />
                  </div>
                </div>
                <div hidden={newvehicleregstate}>
                  <tr className="table_row__first2">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>{" "}
                      Make :{" "}
                    </p>
                    {/* <input style={{ backgroundColor: "whitesmoke", }} value={make} /> */}
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {make}
                    </span>
                  </tr>
                  <tr className="table_row__first2">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>{" "}
                      Model :{" "}
                    </p>
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {model}{" "}
                    </span>
                  </tr>
                </div>
              </div>
            </div>
            <div className="customer_details_1">
              <div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Booking Date :{" "}
                    </p>
                    <input
                      id="userDate"
                      // value={userdetials.BOOKINGDATE}
                      required
                      className="dates"
                      type="date"
                      name="BOOKINGDATE"
                      onChange={(e) => {
                        // handleInputs(e);
                        validateDate();
                      }}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <IoMdTime />{" "}
                      </span>{" "}
                      Preferred Booking Time :{" "}
                    </p>
                    <input
                      type="time"
                      id="bookingTimePreferred"
                      value={userdetials.BOOKINGTIMEPREFERED}
                      onChange={(e) => handleTimeChange(e.target.value)}
                      min="09:00"
                      max="16:00"
                      required
                    />
                  </tr>
                </div>
                {/* <div className="style__card">
                  {" "}
                  <tr>
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        {" "}
                        <FaCity />
                      </span>
                      Country
                    </p>
                    <Select
                      styles={customStyles}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      options={countries.map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                    />
                  </tr>
                </div> */}
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BiSolidUserAccount />{" "}
                      </span>{" "}
                      Report By :{" "}
                    </p>
                    <input
                      required
                      type="text"
                      name="REPORTEDBYINYOURCOMPANY"
                      onChange={(e) => handleInputs(e)}
                    />
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BiCategory />{" "}
                      </span>{" "}
                      Type :{" "}
                    </p>
                    <div className="select__align">
                      <Select
                        required
                        placeholder="Select..."
                        styles={customStyles}
                        name="TOBESENTTOCOMPANY"
                        noOptionsMessage={() => "No Option found"}
                        onChange={(e) => {
                          userdetials.VEHICLECOMPONENT = e.label;
                          userdetials.TOBESENTTOCOMPANY = null;
                          userdetials.TOBESENTTOCOMPANY = null;
                          setCity(null);
                          userdetials.TOBESENTTOCOMPANYBRANCH = null;
                          getGarageList(e.value);
                          setgaragebranch([]);
                        }}
                        // {...userdetials.TOBESENTTOCOMPANY = e.target.value}
                        options={serviceList.map((item) => ({
                          label: item.SERVICENAME,
                          value: item.SERVICEID,
                        }))}
                        value={
                          userdetials.VEHICLECOMPONENT === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.VEHICLECOMPONENT,
                              value: userdetials.VEHICLECOMPONENT,
                            }
                        }
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>
                      Serving Company :
                    </p>
                    <div className="select__align">
                      <Select
                        required
                        // placeholder="Select..."
                        defaultValue={{
                          label: "select a garges",
                          value: "default",
                        }}
                        styles={customStyles}
                        name="TOBESENTTOCOMPANY"
                        noOptionsMessage={() => "No Option found"}
                        value={
                          userdetials.TOBESENTTOCOMPANYID === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.TOBESENTTOCOMPANYID,
                              value: userdetials.TOBESENTTOCOMPANYID,
                            }
                        }
                        onChange={(e) => {
                          userdetials.TOBESENTTOCOMPANYID = e.label;
                          userdetials.TOBESENTTOCOMPANY = e.value
                          setCity(null);
                          getgaragebranch(e.value);
                          userdetials.TOBESENTTOCOMPANYBRANCH = null;
                        }}
                        // {...userdetials.TOBESENTTOCOMPANY = e.target.value}
                        options={togarage.map((item) => ({
                          label: item.OM_GARAGE_NAME,
                          value: item.GARAGEID,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <FaCity />{" "}
                      </span>
                      City :
                    </p>
                    <div className="select__align">
                      <Select
                        defaultValue={{
                          label: "select a city",
                          value: "default",
                        }}
                        value={
                          City === null
                            ? { label: "Select", value: "default" }
                            : { label: City, value: City }
                        }
                        name="city"
                        onChange={(e) => {
                          setselectedcity(e.value);
                          setUserDetials({
                            ...userdetials,
                            TOBESENTTOCOMPANYBRANCH: null,
                          });
                          setCity(e.value);
                        }}
                        options={garagebranch.map((item) => ({
                          label: item.BRANCHCITY,
                          value: item.BRANCHCITY,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <MdOutlineConfirmationNumber />{" "}
                      </span>
                      Branch :
                    </p>
                    <div className="select__align">
                      <Select
                        defaultValue={{
                          label: "select a branch",
                          value: "default",
                        }}
                        value={
                          userdetials.TOBESENTTOCOMPANYBRANCH === null
                            ? { label: "Select", value: "default" }
                            : {
                              label: userdetials.TOBESENTTOCOMPANYBRANCH,
                              value: userdetials.TOBESENTTOCOMPANYBRANCH,
                            }
                        }
                        onChange={(e) => {
                          setUserDetials({
                            ...userdetials,
                            TOBESENTTOCOMPANYBRANCH: e.value,
                          });
                        }}
                        options={garagebranch
                          .filter((item) => item.BRANCHCITY === selectedCity)
                          .map((item) => ({
                            label: item.BRANCHNAME,
                            value: item.BRANCHNAME,
                          }))}
                      />
                    </div>
                  </tr>
                </div>
              </div>

              <div>
                <div className="style__card">
                  {" "}
                  <tr>
                    <p>
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        {" "}
                        <FaCity />
                      </span>
                      Country :
                    </p>
                    <div className="select__align">
                      <Select
                        styles={customStyles}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        options={countries.map((item) => ({
                          value: item.isoCode,
                          label: item.name,
                        }))}
                      />
                    </div>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <AiOutlinePhone />{" "}
                      </span>{" "}
                      Mobile Number :{" "}
                    </p>
                    <input
                      required
                      type="text"
                      pattern="[0-9]{1,15}"
                      name="PROCESSEDBYMOBILENO"
                      value={userdetials.PROCESSEDBYMOBILENO}
                      maxLength="15"
                      onChange={(e) => handleInputs(e)}
                      onInput={(e) => formatMobileNumber(e)}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Processed Date :{" "}
                    </p>
                    <input
                      value={currentDate}
                      required
                      type="date"
                      className="dates"
                      name="PROCESSEDDATE"
                      onChange={(e) => {
                        setCurrentDate(e.target.value);
                      }}
                    ></input>
                  </tr>
                </div>
                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <BsCalendarDate />{" "}
                      </span>{" "}
                      Service Due Date :
                    </p>
                    <input
                      id="userDate2"
                      // oninput={validateDate()}
                      required
                      className="dates"
                      type="date"
                      name="TOBESERVICEDON"
                      onChange={(e) =>
                        // handleInputs(e);
                        validateDate()
                      }
                    />
                  </tr>
                </div>

                <div className="style__card">
                  <tr className="table_row__below">
                    <p>
                      {" "}
                      <span
                        className="icon__for__forms"
                        style={{ fontSize: "18px", width: "20px" }}
                      >
                        <GrNotes />{" "}
                      </span>{" "}
                      Problem Description :{" "}
                    </p>
                    <textarea
                      required
                      className="custom-textarea"
                      type="text"
                      name="PROBLEMDESCRIPTION"
                      onChange={(e) => handleInputs(e)}
                    ></textarea>
                  </tr>
                </div>
              </div>
            </div>
          </table>

          <div className="btn2">
            {/* <button className="button">Clear</button> */}
            <button
              className="button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset
            </button>
            <button className="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </section>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ServiceBooking;
