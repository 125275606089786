import React, { useState } from "react";
import SearchBox from "../Pages/Searchbox.js";
import Maps from "../Pages/Map.js";

function Geolocationnav() {
    const [selectPosition, setSelectPosition] = useState(null);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100vw",
                height: "100vh",
            }}
        >
            <div style={{ width: "50vw", height: "100%" }}>
                <Maps selectPosition={selectPosition} />
            </div>
            <div style={{ width: "50vw", padding: "1rem" }}>
                {selectPosition && (
                    <div>
                        <h2>Selected Position</h2>
                        <p>Latitude: {selectPosition.lat}</p>
                        <p>Longitude: {selectPosition.lon}</p>
                    </div>
                )}
                <div style={{ width: "50vw" }}>
                    <SearchBox selectPosition={selectPosition} setSelectPosition={setSelectPosition} />
                </div>
            </div>
        </div>
    );
}

export default Geolocationnav;
