import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Geolocation from "react-geolocation";
import Loading from "../Components/Loading";
import Garage from "../Image/garage.jpg";
// import Geolocation from "react-geolocation";
import "../Style/Newbranch.css";
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
function NewBranch() {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const intialvalues = {
    GARAGEID: "",
    BRANCHID: "",
    BRANCH_DATE: "",
    GARAGENAME: "",
    GARAGEPHONE: "",
    GARAGEMAINCITY: "",
    GARAGEGEOLOCATION: "",
    BRANCHPHYSICALADDRESS: "",
    // ADDRESS: '',
    // imageValue: '',
  };

  const [branchdetails, setBranchdetails] = useState(intialvalues);
  const [IsLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [garagename, setGaragename] = useState("");
  const [fetchbranchid, setFetchbranchid] = useState([]);
  const [garageid, setgarageid] = useState("");
  const [fetchgaragename, setfetchgaragename] = useState([]);
  const [branchid, setbranchid] = useState("");
  const [garagenumber, setgaragenumber] = useState([]);
  const [fetchgaragefetch, setfetchgaragefetch] = useState([]);
  const [OnlyGarageId, setOnlyGarageId] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [branchIdInput, setBranchIdInput] = useState("");
  const [branchDateInput, setBranchDateInput] = useState("");
  const [garageNameInput, setGarageNameInput] = useState("");
  const [branchNameInput, setBranchNameInput] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  function garageId() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getgarages`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setfetchgaragename(response.data.recordsets[0]);

      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }
  useEffect(garageId, []);

  function BranchId() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getbranchId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setFetchbranchid(response.data.recordset[0]);

        intialvalues.BRANCHID = response.data.recordset[0].BRANCHID;
        setbranchid(response.data.recordset[0].BRANCHID);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }
  useEffect(BranchId, []);

  function GarageBranch(garageid) {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getbranchbygarageId`,
        { garageid: garageid },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setgaragenumber(response.data.recordset);


        setOnlyGarageId(response.data.recordset[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching garage branch:", error);
        setIsLoading(false);
      });
  }

  // const handleGarageSelection = (selectedOption) => {
  //     const selectedBranch = garagenumber.find(branch => branch.BRANCHNUMBER === selectedOption.key);
  //     setfetchgaragefetch(selectedBranch);
  // };

  function FetchBranchDetails(garageid, branchid) {

    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getbranchDetails`,
        {
          GARAGEID: garageid,
          BRANCHID: branchid,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setfetchgaragefetch(response.data.recordset[0]);


      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }

  function HandleInputs(e) {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setBranchdetails({ ...branchdetails, [name]: files[0] });
    } else {
      setBranchdetails({ ...branchdetails, [name]: value });
    }

  }

  function SaveGarage(e) {
    e.preventDefault();
    if (location) {
      const latitude = location.coords.latitude;
      const longitude = location.coords.longitude;
      branchdetails.BRANCHPHYSICALADDRESS = address;
      const Address = address;

    }
    const confirm = window.confirm("Are you sure you want to Save?");
    if (confirm) {
      axios
        .post(`${BASEURL}/insertbranch`, branchdetails, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          alert(response.data.message);

        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setIsLoading(false);
          navigate("/");
        });

    } else {
      window.location.reload();
    }
  }
  // const handleGarageNameChange = (selectedOption) => {

  //     setBranchNameInput("");
  //     setBranchIdInput("");
  //     setBranchDateInput("");
  //     setGarageNameInput("");

  //     setBranchdetails({
  //         ...branchdetails,
  //         GARAGEID: selectedOption.key,
  //     });

  // };
  const handleGetLocation = async (position) => {
    try {
      const { latitude, longitude } = position.coords;
      branchdetails.GARAGEGEOLOCATION = `${latitude}, ${longitude}`;
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );

      if (response.data && response.data.display_name) {
        setAddress(response.data.display_name);
        branchdetails.BRANCHPHYSICALADDRESS = response.data.display_name;
      }
      setLocation(position);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  if (IsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <Navbar2 />
      </div>
      <h1 className="heading_1">Branch Enrollment</h1>
      <form className="form_branch" onSubmit={SaveGarage}>
        <table className="table_branch">
          {/* {fetchgaragefetch.map((item) => ())} */}
          <tr>
            <td colSpan={2}>
              <label>GARAGE NAME </label>
              <Select
                name="GARAGEID"
                onChange={(selectedOption) => {
                  setBranchdetails({
                    ...branchdetails,
                    GARAGEID: selectedOption.key,
                  });
                  setgarageid(selectedOption.key);

                  GarageBranch(selectedOption.key);
                  // handleGarageNameChange()
                  // handleGarageSelection(selectedOption)
                }}
                options={fetchgaragename.map((item) => ({
                  label: item.GARAGENAME,
                  value: item.GARAGENAME,
                  key: item.GARAGENUMBER,
                }))}
              />
            </td>
            <td>
              <label>Garage Id</label>
              {OnlyGarageId.GARAGENUMBER}
            </td>
          </tr>

          <tr>
            <td>
              <label>Branch Id</label>
              {/* <input
                                type="number"
                                name="BRANCHID"
                                defaultValue={branchIdInput}
                                onChange={(e) => HandleInputs(e)}
                            /> */}
              {/* <input type="number" name="BRANCHID" onChange={(e) => HandleInputs(e)} value={garagenumber.BRANCHNUMBER} /> */}
              <span>{fetchgaragefetch.BRANCHID}</span>
            </td>

            <td>
              {/* <label> Registration Date</label>
                            <input
                                type="date"
                                name="BRANCH_DATE"
                                onChange={(e) => HandleInputs(e)}

                            ></input> */}
            </td>
            <td>
              {/* <label>Name</label> */}
              <label>Date</label>
              <input
                type="date"
                name="BRANCH_DATE"
                onChange={(e) => HandleInputs(e)}
                defaultValue={fetchgaragefetch.BRANCH_DATE}
              />
              {/* <input
                                type="text"
                                name="GARAGENAME"
                                onChange={(e) => HandleInputs(e)}
                                contentEditable
                                defaultValue={fetchgaragefetch.BRANCHNAME}
                            ></input> */}
            </td>
          </tr>
          <tr>
            <td>
              <label> Phone Number</label>
              <input
                type="text"
                name="GARAGEPHONE"
                defaultValue={fetchgaragefetch.BRANCHPHONENUMBER}
                onChange={(e) => HandleInputs(e)}
              ></input>
            </td>
            <td>
              <label>BRANCHNAME</label>
              {/* <input
                                type="text"
                                name="GARAGEPHONE"
                                onChange={(e) => HandleInputs(e)}
                                defaultValue={fetchgaragefetch.BRANCHPHONENUMBER}

                            ></input> */}
              <input
                type="text"
                name="GARAGENAME"
                onChange={(e) => HandleInputs(e)}
                defaultValue={fetchgaragefetch.BRANCHNAME}
              />
            </td>
            <td className="table_row_data">
              <label>City</label>
              <input
                type="text"
                name="GARAGEMAINCITY"
                onChange={(e) => HandleInputs(e)}
                defaultValue={fetchgaragefetch.BRANCHCITY}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <label>Geo-location</label>

              {location ? (
                <div>
                  <input
                    type="text"
                    name="latitude"
                    value={location.coords.latitude}
                    readOnly
                  />
                  <input
                    type="text"
                    name="longitude"
                    value={location.coords.longitude}
                    readOnly
                  />
                  {address}
                </div>
              ) : null}
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "right" }}>
              <Geolocation
                render={({ fetchingPosition, position, error }) => (
                  <div>
                    <button
                      className="geo_location"
                      type="button"
                      onClick={(e) => {
                        handleGetLocation(position);
                        HandleInputs(e);
                      }}
                    >
                      Get My Location
                    </button>
                    {fetchingPosition ? <p>Fetching position...</p> : null}
                    {error ? <p>Error: {error.message}</p> : null}
                  </div>
                )}
              />
            </td>
          </tr>

          <tr className="btn3" colspan={2}>
            <td></td>
            <td></td>
            <td>
              <button className="button_branch_save" type="submit">
                Save
              </button>
            </td>
          </tr>
        </table>
      </form>
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <th>BRANCHNUMBER</th>
              <th>BRANCHNAME</th>

              <th>BRANCHADDRESS</th>
              <th>BRANCHPHONENUMBER</th>

              <th>BRANCHADDRESS</th>
              <th>BRANCHCITY</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {garagenumber.map((item) => (
              <tr>
                <td>{item.BRANCHNUMBER}</td>
                <td>{item.BRANCHNAME}</td>
                <td>{item.BRANCHADDRESS}</td>

                <td>{item.BRANCHPHONENUMBER}</td>

                <td>{item.BRANCHADDRESS}</td>
                <td>{item.BRANCHCITY}</td>
                <td
                  onClick={() => {
                    FetchBranchDetails(garageid, item.BRANCHNUMBER);
                    setEditMode(true);
                  }}
                >
                  Edit
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default NewBranch;
