import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar3 from "../Components/Navbar3";
import Footer from "../Components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import moment from "moment";

function Garagedetails() {
  const navigate = useNavigate();
  const selector = useSelector((state) => state.garagereducer);
  const [imageData, setImageData] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  function fetchDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getgaragedetails`,
        {
          garageid: selector.GARAGENUMBER,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        const binaryData = new Uint8Array(
          response.data.data.MAINPAGEIMAGE?.data
        );

        const blob = new Blob([binaryData], { type: "image/jpeg" }); // Adjust MIME type if needed

        // Create an object URL from the Blob
        const imageUrl = URL.createObjectURL(blob);


        setImageData(imageUrl);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        // navigate("/");
      });
  }
  useEffect(fetchDetails, []);
  if (IsLoading) {
    return (
      <div>
        {" "}
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <Navbar3 />
      <div className="detail__main">
        <main className="garage__deatail__section">
          {" "}
          <header
            className="header__of__main"
            style={{ margin: "0px", marginLeft: "0px" }}
          >
            <h1
              className="header__of__page"
              style={{ margin: "0px", margin: "0px 0px 10px 0px" }}
            >
              {" "}
              Garage Details
            </h1>
          </header>
          <main className="main__style__gaurage">
            <section className="section1__grage__deatail">
              <main className="main__for___deatail">
                <p>
                  <b>Garage Number :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGENUMBER ? selector.GARAGENUMBER : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Name :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGENAME ? selector.GARAGENAME : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Registration Date :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.REGISTEREDDATE
                      ? moment(selector.REGISTEREDDATE).format("DD-MM-YYYY")
                      : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Email :&nbsp;</b>{" "}
                  <div style={{ textTransform: "lowercase" }}>
                    {" "}
                    {selector.GARAGEEMAIL ? selector.GARAGEEMAIL : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Phone :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGAEPHONE ? selector.GARAGAEPHONE : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Country :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGECOUNTRY ? selector.GARAGECOUNTRY : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage City :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGECITY ? selector.GARAGECITY : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Specialization :&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.GARAGESPECIALIZATION
                      ? selector.GARAGESPECIALIZATION
                      : "N/A"}
                  </div>
                </p>
                <p>
                  <b>Garage Website:&nbsp;</b>{" "}

                  {" "}
                  <div
                    style={{
                      textTransform: "lowercase",
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                      width: "100%"
                    }}
                  >
                    <a

                      onClick={(e) => {
                        window.open(`${selector.WEBSITE}`);
                      }}
                    >
                      {" "}
                      {selector.WEBSITE ? selector.WEBSITE : "N/A"}
                    </a>
                  </div>

                </p>
                <p>
                  <b>Garage Description:&nbsp;</b>{" "}
                  <div>
                    {" "}
                    {selector.MAINPAGEINFORMATION
                      ? selector.MAINPAGEINFORMATION
                      : "N/A"}
                  </div>
                </p>
              </main>
            </section>
            <section className="">
              {/* Conditional rendering for the image */}
              {imageData ? (
                <img
                  src={imageData}
                  height="300px"
                  width="400px"
                  alt="Image"
                  className="image__garage__detail"
                  loading="lazy"
                />
              ) : (
                <div className="default-image-placeholder">
                  {/* Default styling when imageData is not available */}
                  <div id="card">
                    <div class="card-image">
                      <div class="block pulsate"></div>
                      <div class="block pulsate"></div>
                      <div class="block pulsate"></div>
                    </div>
                    <div class="card-image">
                      <div class="block pulsate"></div>
                      <div class="block pulsate"></div>
                    </div>
                    <div class="card-content">
                      <div class="block2 pulsate"></div>
                      <div class="block3 pulsate"></div>
                      <div class="circle pulsate"></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </main>
        </main>
      </div >

      <div>
        <div>
          <Footer />
        </div>
      </div>
    </div >
  );
}

export default Garagedetails;
