import React, { useEffect, useState } from "react";
import "../Style/Services.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Navbar4 from "../Components/Navbar4";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Servicesdetails() {
  const navigate = useNavigate();
  const [detials, setDetials] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const selector = useSelector((state) => state.servicereducer);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  // function servicesdetialsofindividual() {
  //   setLoading(true);
  //   axios
  //     .get(`${BASEURL}/getservices`, {
  //       headers: {
  //         "auth-token": authToken,
  //       },
  //     })
  //     .then(
  //       (response) => (setDetials(response.data.recordset), setLoading(false))
  //     )
  //     .catch(() => {
  //       alert("Internal server error! please try after some time");
  //       setLoading(false);
  //       // navigate("/");
  //     });
  // }
  // useEffect(servicesdetialsofindividual, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar4 />

      <header className="header__of__main" style={{ margin: "10px" }}>
        <h1 className="header__of__page"> Service Details</h1>
      </header>
      <div className="service__details__main">
        <section className="table__service__detail">
          <main className="service_details">
            <div className="bookin__side1">
              <aside>
                {" "}
                <p>Booking ID :&nbsp;</p>
                <div style={{ color: "red" }}>{selector.BOOKINGID} </div>{" "}
              </aside>
              <aside>
                {" "}
                <p>Account Code:&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.ACCOUNTCODE ? selector.ACCOUNTCODE : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                {" "}
                <p>Booking Date :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.BOOKINGDATE
                    ? moment(selector.BOOKINGDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                {" "}
                <p>Type :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.VEHICLEORCOMPONENT
                    ? selector.VEHICLEORCOMPONENT
                    : "N/A"}
                </div>
              </aside>
              <aside>
                {" "}
                <p>Asset Registration :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.ASSETREGISTRATION
                    ? selector.ASSETREGISTRATION
                    : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                {" "}
                <p>Make :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.MAKE ? selector.MAKE : "N/A"}{" "}
                </div>{" "}
              </aside>
              <aside>
                {" "}
                <p>Model :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.MODEL ? selector.MODEL : "N/A"}{" "}
                </div>{" "}
              </aside>
            </div>
            <div className="bookin__side2">
              <aside>
                <p>Serviced Company :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.BOOKEDINCOMPANY ? selector.BOOKEDINCOMPANY : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                <p>Branch :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.BRANCH ? selector.BRANCH : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                <p>Service Due Date:&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.TOSERVICEDATE
                    ? moment(selector.TOSERVICEDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                <p>Reported By :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.REPORTEDBY ? selector.BRANCH : "N/A"}
                </div>{" "}
              </aside>
              <aside>
                <p>Process Date :&nbsp;</p>
                <div style={{ color: "red" }}>
                  {selector.PROCESSEDDATE
                    ? moment(selector.PROCESSEDDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </div>
              </aside>
              <aside>
                {" "}
                <p>Problems Reported :&nbsp;</p>
                <div style={{ color: "red", textTransform: "uppercase" }}>
                  {selector.PROBLEMSREPORTED
                    ? selector.PROBLEMSREPORTED
                    : "N/A"}
                </div>
              </aside>
            </div>
          </main>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Servicesdetails;
