import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import Navbar3 from "../Components/Navbar3";
import Footer from "../Components/Footer";
import { garagedetails } from "../Redux/Action/action";
import { branchdetails } from "../Redux/Action/action";
import { FaSearch } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc";
import { GrAdd, GrClose } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import "../Style/garagedetails.css";
import Loading from "../Components/Loading";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { BiSolidAddToQueue } from "react-icons/bi";
import { useSelector } from "react-redux";
function GaregeInformation() {
  const [getgarages, setGetgarages] = useState([]);
  const [Filterdata, setFilterdata] = useState("");
  const [sortBy, setSortBy] = useState("GARAGENUMBER");
  const [sortOrder, setSortOrder] = useState("desc");
  const [IsLoading, setIsLoading] = useState("false");
  const [approve, setapprove] = useState([]);
  const [Appgarages, setAppgarages] = useState("");
  const [message, setmessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let updatedApprove = [];
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const selector = useSelector((state) => state.garagereducer);
  console.log(selector);

  //   if (IsLoading) {
  //     return <Loading />;
  //   }

  return (
    <div className="bg-slate-50">
      <Navbar3 />
      <header className="m-6 p-4  rounded-lg shadow-lg bg-white">
        <h1 className="text-2xl font-bold text-center mb-6">
          Detail Garage Information
        </h1>
        <aside className="flex justify-between lg:flex-row-reverse flex-col  gap-6 w-full ">
          <p>
            <div className=" justify-center">
              <img
                className="w-[400px] h-[200px] object-cover rounded-lg shadow-md"
                src={selector?.MAINPAGEIMAGE}
                alt="Garage"
              />
            </div>
            <div className="flex justify-center">
              <button
                className="text-xl  bg-blue-200 rounded p-2 my-3 font-bold"
                onClick={() => navigate("/ServingBooking")}
              >
                BOOK SERVICE{" "}
              </button>
            </div>
          </p>
          <p className="lg:w-[60%]">
            <table className=" w-full  border-collapse border border-gray-300">
              <tbody>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700 w-[200px]">
                    Garage Name
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGENAME}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Garage Number
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGENUMBER}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Specialization
                  </td>
                  <td className="px-4 py-2">
                    {selector?.GARAGESPECIALIZATION}
                  </td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Website
                  </td>
                  <td className="px-4 py-2">{selector?.WEBSITE}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Email
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGEEMAIL}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Phone
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGAEPHONE}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Country
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGECOUNTRY}</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    City
                  </td>
                  <td className="px-4 py-2">{selector?.GARAGECITY}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 font-semibold text-gray-700">
                    Registered Date
                  </td>
                  <td className="px-4 py-2">
                    {new Date(selector?.REGISTEREDDATE).toLocaleDateString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </p>
        </aside>
      </header>
      <Footer />
    </div>
  );
}

export default GaregeInformation;
