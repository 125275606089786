const initialState = {
    GARAGENUMBER: "",
    GARAGENAME: ""
}

function branchreducer(state = initialState, action) {
    switch (action.type) {
        case 'GARAGEID':
            return action.data
        case 'CLEAR_BRANCH':
            return initialState;
        default:
            return state;
    }
}
export default branchreducer