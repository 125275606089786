import React from "react";
import { useEffect, useState } from "react";

import Navbaruser from "../Components/Navbaruser";
import "../Style/AddUsers.css";
import axios from "axios";
import Select from "react-select";
import Loading from "../Components/Loading";
import { BsCalendarDate } from "react-icons/bs";
import { BiUserPin } from "react-icons/bi";
import {
  MdOutlineAlternateEmail,
  MdDriveFileRenameOutline,
  MdPassword,
} from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { GoNumber } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

function Garageusers() {
  const selector = useSelector((state) => state.LoginReduceruser);

  const garageId = selector.garageId;
  const intialvalues = {
    GARAGEID: "",
    GARAGEUSERNAME: "",
    GARAGEUSERPASSWORD: "",
    GARAGEUSEREMAIL: "",
    GARAGEUSERECREATEDDATE: "",
    GARAGEUSERECREATEDBY: "",
  };
  const [details, setDetials] = useState(intialvalues);
  const [garages, setgarages] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const [isEmailValid, setIsEmailValid] = useState(true);
  // const [err, Seterr] = useState("")
  const [isEmailValid, setEmailError] = useState(true);
  const [isValidationError, setIsValidationError] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function showExpiryAlertOld() {
    let alertMessage = "Please fill in all required fields.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Please select geolocation.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  function GaregeDetails() {
    setLoading(true);
    axios
      .post(
        `${BASEURL}/getGarageName`,
        {
          garageid: garageId,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setgarages(response.data.recordset[0].GARAGENAME);
        setLoading(false);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
      });
  }
  useEffect(GaregeDetails, []);
  function handleinputs(e) {
    const { name, value } = e.target;
    setDetials({ ...details, [name]: value });

    if (name === "GARAGEUSEREMAIL") {
      const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const isValid = emailRegex.test(value);

      if (isValid) {
        setEmailError("");
        setIsValidationError(false);
      }
    }
  }
  function SaveUser() {
    const inputEmail = details.GARAGEUSEREMAIL;
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(inputEmail);

    if (!isValid) {
      setEmailError("Enter a valid email!");
      setIsValidationError(true);
      return;
    } else {
      if (
        !details.GARAGEUSERNAME ||
        !details.GARAGEUSERPASSWORD ||
        !details.GARAGEUSEREMAIL ||
        !details.GARAGEUSERECREATEDBY
      ) {
        showExpiryAlertOld();
        return;
      }
      details.GARAGEID = garageId;

      setLoading(true);

      const confirm = window.confirm("Are you sure you want to Save?");
      if (confirm) {
        axios
          .post(`${BASEURL}/insertGarageUser`, details, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            alert(response.data.message);
            setLoading(false);
            window.location.reload();
          })
          .catch(() => {
            alert("Internal server error! please try after some time");
          });
      } else {
        setLoading(false);
      }
    }
  }

  function handlepassword() {
    setShow(!show);
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbaruser />
      {showAlert && <div className="overlay"></div>}
      <div>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms"
                style={{
                  fontSize: "25px",
                  width: "20px",
                  textAlign: "RIGHT",
                  position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                <AiOutlineClose />{" "}
              </span>
            </p>
          </div>
        )}
      </div>
      <div style={{ padding: "20px" }}>
        <div className="vehicle__registration">
          <form className="forM__style__vehicle__reg_">
            <header
              className="header__of__main"
              style={{ margin: "0px", marginLeft: "0px" }}
            >
              <h1
                className="header__of__page"
                style={{ margin: "0px", margin: "0px 0px 10px 0px" }}
              >
                {" "}
                User Registration
              </h1>
            </header>

            <div className="section">
              <div className="input__group">
                <p className="garageid_">
                  <span className="icon__for__forms">
                    <GiHomeGarage />
                  </span>
                  <p className="media_garagename">GARAGE NAME : &nbsp;</p>
                  <p style={{ color: "red" }}>{garages}</p>
                </p>
                {/* <span>
                                    {garages}
                                </span> */}
              </div>
              <div className="input__group">
                <p className="garageid_">
                  <span className="icon__for__forms">
                    <GoNumber />
                  </span>
                  <p>GARAGEID : </p> &nbsp;
                  <p style={{ color: "red" }}>{garageId}</p>
                </p>
              </div>
              <div className="input__group">
                <p>
                  <span className="icon__for__forms">
                    <MdDriveFileRenameOutline />
                  </span>
                  USER NAME : &nbsp;
                </p>

                <input
                  name="GARAGEUSERNAME"
                  type="text"
                  onChange={(e) => handleinputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  <span className="icon__for__forms">
                    <BiUserPin />
                  </span>
                  USER CREATED BY : &nbsp;
                </p>

                <input
                  name="GARAGEUSERECREATEDBY"
                  type="text"
                  onChange={(e) => handleinputs(e)}
                />
              </div>
              <div className="input__group">
                <p>
                  <span className="icon__for__forms">
                    <MdOutlineAlternateEmail />
                  </span>
                  USER EMAIL : &nbsp;
                </p>

                <input
                  name="GARAGEUSEREMAIL"
                  type="email"
                  onChange={(e) => handleinputs(e)}
                />

                {isValidationError && (
                  <p className="errmsg" aria-live="assertive">
                    {isEmailValid}
                  </p>
                )}
              </div>

              <div className="input__group">
                <p>
                  <span className="icon__for__forms">
                    <MdPassword />
                  </span>
                  USER PASSWORD : &nbsp;
                </p>
                <input
                  name="GARAGEUSERPASSWORD"
                  type={show ? "text" : "password"}
                  onChange={(e) => handleinputs(e)}
                />
                <span className="icon-show-pass" onClick={handlepassword}>
                  <AiOutlineEye />
                </span>
              </div>
            </div>
            <div style={{ padding: "10px" }}>
              <div className="btn__Submit__reg">
                <p>
                  <button
                    onClick={() => window.location.reload()}
                    className="button"
                  >
                    Clear
                  </button>
                </p>
                <p>
                  <button
                    type="button"
                    className="button"
                    onClick={(e) => SaveUser(e)}
                  >
                    {" "}
                    Save
                  </button>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Garageusers;
