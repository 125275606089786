const initialState = false;

const loginstatereducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGINSTATE":
            return action.data;

        default:
            return state;
    }
};

export default loginstatereducer;