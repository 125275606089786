import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Geolocation from "react-geolocation";
import Select from "react-select";
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";
import Maps from "../Pages/Map.js";
import SearchBox from "../Pages/Searchbox.js";
import Geolocationnav from "./Geolocationnav.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { branchdetails, clearBranch } from "../Redux/Action/action";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai"
// import { useLocation } from 'react-router-dom';
import {
  BiSolidUserAccount,
  BiUserPin,
  BiSolidSend,
  BiCategory,
} from "react-icons/bi";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { GrFormClose } from "react-icons/gr"
import { BiSolidEditAlt } from "react-icons/bi";
import { GiMechanicGarage, GiHomeGarage } from "react-icons/gi";
import { PiGarageBold } from "react-icons/pi";
import { BiRename } from "react-icons/bi";
import { GrMapLocation } from "react-icons/gr";
import { AiTwotonePhone } from "react-icons/ai";
import { FaCity, FaLocationCrosshairs } from "react-icons/fa";
import { MdDateRange, MdEditLocationAlt } from "react-icons/md";
import Loading from "../Components/Loading";
import { Country, City } from "country-state-city";
// import Input from "react-select/dist/declarations/src/components/Input";
function BranchRegistration() {
  const navigate = useNavigate();
  const intialvalues = {
    GARAGEID: "",
    BRANCHNUMBER: "",
    BRANCHREGISTEREDDATE: "",
    BRANCHNAME: "",
    BRANCHPHONE: "",
    BRANCHMAINCITY: "",
    BRANCHPHYSICALADDRESS: "",
    COUNTRY: "",
    // ADDRESS: '',
    // imageValue: '',
  };
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState(null);
  const [branchdetails, setBranchdetails] = useState(intialvalues);
  const [fetchbranchid, setFetchbranchid] = useState([]);
  const [garageid, setgarageid] = useState("");
  const [fetchgaragename, setfetchgaragename] = useState([]);
  const [branchgaragedetails, setbranchgaragedetails] = useState([]);
  const [garageId, setgarageId] = useState(null);
  const [editingBranch, setEditingBranch] = useState(null);
  const [handlecondition, sethandlecondition] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectPosition, setSelectPosition] = useState(null);
  const [IsLoading, setIsLoading] = useState("false");
  const [currentDate, setCurrentDate] = useState("");
  const [cns, setcns] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  // const [cities, setCities] = useState([]);

  const [test, customTest] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function showExpiryAlertOld() {
    let alertMessage = "Please fill in all required fields.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }
  function geolocationAlert() {
    let alertMessage = "Please select geolocation.";
    customTest(alertMessage); // Display the alert message
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const countries = Country.getAllCountries();
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(
        countryCities.map((city) => ({ value: city.name, label: city.name }))
      );
      const selectedCountryObject =
        Country.getCountryByCode(selectedCountryIso);

      const phoneCode = selectedCountryObject.phonecode || "";

      setBranchdetails((prevDetails) => ({
        ...prevDetails,
        BRANCHPHONE: `+${phoneCode}`,
      }));
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    branchdetails.BRANCHMAINCITY = selectedOption.value

  };
  // const [newbranchreg, setnewbranchreg] = useState("")

  const selector = useSelector((state) => state.branchreducer);


  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearBranch());
    };
  }, [dispatch]);

  function BranchId() {
    axios
      .get(`${BASEURL}/getbranchId`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setFetchbranchid(response.data.recordset[0]);

        intialvalues.BRANCHID = response.data.recordset[0].BRANCHID;
        branchdetails.BRANCHNUMBER = response.data.recordset[0].BRANCHID;
        // setbranchid(response.data.recordset[0].BRANCHID)
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        // navigate("/");
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  function garagename() {
    axios
      .get(`${BASEURL}/getgarages`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setfetchgaragename(response.data.recordsets[0]);

      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        // navigate("/");
      });
    if (selector) {
      axios
        .post(`${BASEURL}/getbranchbygarageId`, {
          garageid: selector.GARAGENUMBER,
        },
          {
            headers: {
              "auth-token": authToken,
            },
          })
        .then((response) => {
          setbranchgaragedetails(response.data.recordset);

        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  useEffect(garagename, []);
  useEffect(BranchId, []);

  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  function formatMobileNumber(event) {
    const input = event.target;
    const unformattedNumber = input.value.replace(/[^\d+\- ]/g, "");

    if (unformattedNumber.length <= 15) {
      input.value = unformattedNumber;
    } else {
      const formattedNumber = unformattedNumber.replace(
        /(\d{3})(\d{4})(\d{4})(\d+)/,
        "$1$2$3$4"
      );
      input.value = formattedNumber;
    }
  }

  function getBranchgarageid(garageid) {
    axios
      .post(
        `${BASEURL}/getbranchbygarageId`,
        { garageid: garageid },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setbranchgaragedetails(response.data.recordset);

        // setOnlyGarageId(response.data.recordset[0]);
      })
      .catch((error) => {
        console.error("Error fetching garage branch:", error);
      });
  }

  function handleEditClick(branch) {
    // const countryCities = City.getCitiesOfCountry(selectedCountryIso);
    // setCities(
    //   countryCities.map((city) => ({ value: city.name, label: city.name }))
    // );

    setEditingBranch(branch);

    setSelectedCity(branch.BRANCHCITY);
    setBranchdetails({
      BRANCHNUMBER: branch.BRANCHNUMBER,
      BRANCHNAME: branch.BRANCHNAME,
      BRANCHPHYSICALADDRESS: branch.BRANCHADDRESS,
      BRANCHPHONE: branch.BRANCHPHONENUMBER,
      BRANCHMAINCITY: branch.BRANCHCITY,
      BRANCHREGISTEREDDATE: new Date().toISOString().split("T")[0],
      GARAGEID: branch.GARAGENUMBER,
      GARAGEGEOLOCATION: branch.BRANCHGEOLOCATION,
    });
    // setLocation(branch.BRANCHGEOLOCATION);
    branch.BRANCHGEOLOCATION?.split(",")[0] &&
      branch.BRANCHGEOLOCATION?.split(", ")[1]
      ? setSelectPosition({
        lat: branch.BRANCHGEOLOCATION?.split(",")[0],
        lon: branch.BRANCHGEOLOCATION?.split(", ")[1],
      })
      : setcns(branch.BRANCHGEOLOCATION?.split(", ")[1]);
  }
  function handleCodeInput(dat) {

    const selectedOption = fetchgaragename.find(
      (item) => item.GARAGENUMBER == dat
    );

    if (selectedOption) {

      setgarageId(selectedOption.GARAGENAME);
      sethandlecondition(true);

    } else {
      sethandlecondition(false);

    }
  }

  function OnChangeSelect() {
    setBranchdetails({
      ...branchdetails,
      BRANCHID: "",
      BRANCHNAME: "",
      BRANCHPHONE: "",
      BRANCHMAINCITY: "",
      BRANCH_DATE: "",
      BRANCHPHYSICALADDRESS: "",
    });
  }
  function HandleInputs(e) {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setBranchdetails({ ...branchdetails, [name]: files[0] });
    } else {
      setBranchdetails({ ...branchdetails, [name]: value });
    }
  }



  function SaveGarage(e) {
    e.preventDefault();

    // if (
    //   !branchdetails.BRANCHNAME ||
    //   !branchdetails.BRANCHPHYSICALADDRESS ||
    //   // !branchdetails.BRANCHMAINCITY ||
    //   !branchdetails.BRANCHPHONE.length > 5 ||
    //   !garageid
    // ) {
    //   showExpiryAlertOld()
    //   return;
    // }


    if (
      !branchdetails.BRANCHPHYSICALADDRESS ||
      // !branchdetails.BRANCHMAINCITY ||
      !branchdetails.BRANCHPHONE.length > 5 ||
      !branchdetails.BRANCHNAME
    ) {
      showExpiryAlertOld();
      return;
    }

    if (selector.GARAGENAME) {

    } else if (!garageid) {
      // showExpiryAlertOld();
      return;
    }
    if (!selectPosition) {
      geolocationAlert();

      return;
    }

    // branchdetails.GARAGEID = selector.GARAGENUMBER


    if (selectPosition) {
      const latitude = selectPosition.lat;
      const longitude = selectPosition.lon;

      branchdetails.GARAGEID = garageid ? garageid : selector.GARAGENUMBER;
      // branchdetails.BRANCHMAINCITY = selectedCity.label;

      branchdetails.BRANCHREGISTEREDDATE = currentDate;
      branchdetails.GARAGEGEOLOCATION = `${latitude}, ${longitude}`;


    }
    const confirm = window.confirm("Are you sure you want to Save?");
    if (confirm) {
      setIsLoading(true);
      axios
        .post(`${BASEURL}/insertbranch`, branchdetails, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          alert(response.data.message);

          window.location.reload();
          setIsLoading(false);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setIsLoading(false);
          // navigate("/");
        });

    }
  }
  const handleGetLocation = async (position) => {
    try {
      const { latitude, longitude } = position.coords;
      branchdetails.GARAGEGEOLOCATION = `${latitude}, ${longitude}`;
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      if (response.data && response.data.display_name) {
        setAddress(response.data.display_name);
        branchdetails.BRANCHPHYSICALADDRESS = response.data.display_name;
      }
      setLocation(position);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  // const garageid = selector.GARAGENUMBER
  // if (selector) {

  //   useEffect(newbranchregistration, [])

  // }

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "290px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
    }),

    // option: (base, state) => ({
    //   ...base,
    //   zIndex: 110,
    // }),

    "@media (max-width: 720px)": {
      control: (baseStyles, state) => ({
        ...baseStyles,
        Width: "200px",
        backgroundColor: "red"
      }),
    }

  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0, // Change this to the desired position to scroll to
      behavior: "smooth", // Apply the smooth scrolling behavior
    });
  };
  if (IsLoading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar2 />
      {showAlert && <div className="overlay"></div>}
      <div style={{ zIndex: 2000 }}>
        {showAlert && (
          <div className="custom-alert  animation__Style">
            <p className="align__button">
              <p>{test}</p>
              <span
                className="icon__for__forms_"
                style={{
                  fontSize: "25px", width: "20px", textAlign: "RIGHT", position: "relative",
                  top: "-17px",
                  marginLeft: "15px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={handleCloseAlert}
              >
                {/* <GrFormClose /> */}
                <AiOutlineClose />
                {/* <MdOutlineConfirmationNumber /> */}

              </span>


            </p>

          </div>
        )}
      </div>

      <section className="main__sec__Align">
        <main>
          <header
            className="header__of__main"
            style={{ margin: "0px", marginLeft: "0px" }}
          >
            <h1
              className="header__of__page"
              style={{ margin: "0px", margin: "0px 0px 10px 0px" }}
            >
              {" "}
              Branch Registration
            </h1>
          </header>
          <table className="table__geo">
            <tr>
              <td>
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <MdOutlineConfirmationNumber />{" "}
                  </span>{" "}
                  BRANCH NUMBER :&nbsp;
                </p>
                <div
                  style={{
                    margin: "0px 10px",
                    color: "red",
                    fontWeight: "600",
                    fontSize: "18px ",
                  }}
                >
                  {branchdetails.BRANCHNUMBER}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {" "}
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <GiHomeGarage />{" "}
                  </span>{" "}
                  Garage Name :&nbsp;
                </p>
                {selector.GARAGENAME ? (
                  <div>
                    <input

                      required
                      className="select__geo__loc_1"
                      name="GARAGENAME"
                      value={selector.GARAGENAME}
                    // {branchdetails.GARAGEID = selector.GARAGENAME}
                    // newbranchregistration
                    />
                  </div>
                ) : (
                  <div>
                    <Select
                      required
                      styles={customStyless}
                      className="select__geo__loc"
                      name="GARAGENAME"
                      noOptionsMessage={() => "No Option found"}
                      // placeholder={selector.GARAGENAME}
                      value={
                        garageId
                          ? { label: garageId, value: garageId }
                          : undefined
                      }
                      onChange={(selectedOption) => {
                        setgarageid(selectedOption.key);

                        getBranchgarageid(selectedOption.key);
                        branchdetails.GARAGEID = selectedOption.key;
                        OnChangeSelect();
                      }}
                      options={fetchgaragename.map((item) => ({
                        label: item.GARAGENAME,
                        value: item.GARAGENAME,
                        key: item.GARAGENUMBER,
                      }))}
                    />
                  </div>
                )}
              </td>
            </tr>

            <tr>
              <td>
                {" "}
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <PiGarageBold />{" "}
                  </span>{" "}
                  BRANCH GARAGE ID :&nbsp;
                </p>{" "}
                <input
                  required
                  // placeholder={selector.GARAGENUMBER}
                  disabled
                  style={{
                    background: "white",
                    border: "1px solid",
                    borderRadius: "2px",
                  }}
                  type="number"
                  name="GARAGENUMBER"
                  defaultValue={garageid ? garageid : selector.GARAGENUMBER}
                  onChange={(e) => {
                    handleCodeInput(e.target.value);
                    HandleInputs(e);
                    sethandlecondition(true);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <BiRename />{" "}
                  </span>{" "}
                  BRANCH NAME :&nbsp;
                </p>{" "}
                <input
                  required
                  type="text"
                  name="BRANCHNAME"
                  value={branchdetails.BRANCHNAME}
                  id="BRANCHNAME"
                  onChange={(e) => HandleInputs(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <GrMapLocation />{" "}
                  </span>{" "}
                  BRANCH PHYSICAL ADDRESS :&nbsp;
                </p>{" "}
                <input
                  type="text"
                  name="BRANCHPHYSICALADDRESS"
                  id="BRANCHPHYSICALADDRESS"
                  required
                  defaultValue={branchdetails.BRANCHPHYSICALADDRESS}
                  onChange={(e) => {
                    branchdetails.BRANCHPHYSICALADDRESS = e.target.value;
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "25px" }}
                  >
                    <FaCity />
                  </span>
                  COUNTRY :&nbsp;
                </p>
                <Select
                  name="COUNTRY"
                  styles={customStyless}
                  required
                  className="select__geo__loc__"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countries.map((country) => ({
                    value: country.isoCode,
                    label: country.name,
                  }))}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "25px" }}
                  >
                    <FaCity />
                  </span>
                  BRANCH CITY :&nbsp;
                </p>{" "}
                <Select
                  name="BRANCHMAINCITY"
                  styles={customStyless}
                  required
                  className="select__geo__loc_"
                  placeholder={branchdetails.BRANCHMAINCITY}
                  onChange={handleCityChange}
                  options={cities}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "25px" }}
                  >
                    <AiTwotonePhone />
                  </span>
                  BRANCH PHONE :&nbsp;
                </p>
                <input
                  required
                  type="text"
                  id="BRANCHPHONE"
                  name="BRANCHPHONE"
                  pattern="[0-9]{1,15}"
                  // maxLength="15"
                  value={branchdetails.BRANCHPHONE}
                  onChange={(e) => HandleInputs(e)}
                  onInput={(e) => formatMobileNumber(e)}
                // placeholder={`+${branchdetails.BRANCHPHONE}`}
                ></input>
              </td>
            </tr>

            <tr>
              <td>
                {" "}
                <p>
                  {" "}
                  <span
                    className="icon__for__forms"
                    style={{ fontSize: "18px", width: "20px" }}
                  >
                    <MdDateRange />{" "}
                  </span>{" "}
                  BRANCH REGISTERED DATE :&nbsp;
                </p>
                <input
                  required
                  value={currentDate}
                  disabled
                  type="date"
                  name="BRANCHREGISTEREDDATE"
                  id="BRANCHREGISTEREDDATE"
                // onChange={(e) => HandleInputs(e)}
                ></input>
              </td>
            </tr>
          </table>
        </main>
        <main>
          <h3>
            {" "}
            <span
              className="icon__for__forms"
              style={{ fontSize: "18px", width: "20px" }}
            >
              <MdEditLocationAlt />{" "}
            </span>{" "}
            BRANCH GEO LOCATION{" "}
          </h3>
          {location ? (
            <div>
              <input
                type="text"
                name="latitude"
                value={location.coords?.latitude || location.split(",")[0]}
                readOnly
              />
              <input
                value={location.coords?.latitude || location.split(",")[1]}
                type="text"
                name="longitude"
                readOnly
              />
              {address}
            </div>
          ) : null}
          <div style={{ width: "100%", height: "auto", zIndex: "0" }}>
            <Maps selectPosition={selectPosition} />
          </div>
          {selectPosition && (
            <div>
              <h2>Selected Position</h2>
              <p>Latitude: {selectPosition.lat}</p>
              <p>Longitude: {selectPosition.lon}</p>
            </div>
          )}
          <div>
            <SearchBox
              selectPosition={selectPosition}
              setSelectPosition={setSelectPosition}
            />
          </div>
          <button className="button_branch_save" onClick={SaveGarage}>
            SAVE
          </button>
        </main>
      </section>
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <th style={{ textAlign: "right", paddingRight: "20px" }}>
                BRANCH NUMBER{" "}
              </th>
              <th>BRANCH NAME </th>
              <th>BRANCH ADDRESS</th>
              <th style={{ textAlign: "right", paddingRight: "20px" }}>
                BRANCH PHONE NUMBER
              </th>
              <th>BRANCH CITY</th>
              <th>EDIT</th>
            </tr>
          </thead>
          <tbody>
            {branchgaragedetails.map((item) => (
              <tr>
                <td style={{ textAlign: "right", paddingRight: "20px" }}>
                  {item.BRANCHNUMBER}
                </td>
                <td>{item.BRANCHNAME}</td>
                <td>{item.BRANCHADDRESS}</td>
                <td style={{ textAlign: "right", paddingRight: "20px" }}>
                  {item.BRANCHPHONENUMBER}
                </td>
                <td>{item.BRANCHCITY ? item.BRANCHCITY : "N/A"}</td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    handleEditClick(item);
                    scrollToSection();
                  }}
                >
                  <BiSolidEditAlt />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div >
  );
}

export default BranchRegistration;
