import React from 'react'
import { servicedetails } from "../Redux/Action/action";
import Navbaruser2 from "../Components/Navbaruser2";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Footer from "../Components/Footer";

function Userservicedetails() {
    const selector = useSelector((state) => state.servicereducer);



    return (
        <div>
            <Navbaruser2 />
            <header className="header__of__main" style={{ margin: "10px" }}>
                <h1 className="header__of__page"> Service Details</h1>
            </header>
            <div className="service__details__main">
                <section className="table__service__detail">
                    <main className="service_details">
                        <div className="bookin__side1">
                            <aside>
                                {" "}
                                <p>Booking ID :&nbsp;</p>
                                <div style={{ color: "red" }}>{selector.BOOKINGID} </div>{" "}
                            </aside>
                            <aside>
                                {" "}
                                <p>Account Code:&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.ACCOUNTCODE ? selector.ACCOUNTCODE : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                {" "}
                                <p>Booking Date :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.BOOKINGDATE
                                        ? moment(selector.BOOKINGDATE).format("DD/MM/YYYY")
                                        : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                {" "}
                                <p>Type :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.VEHICLEORCOMPONENT
                                        ? selector.VEHICLEORCOMPONENT
                                        : "N/A"}
                                </div>
                            </aside>
                            <aside>
                                {" "}
                                <p>Asset Registration :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.ASSETREGISTRATION
                                        ? selector.ASSETREGISTRATION
                                        : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                {" "}
                                <p>Make :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.MAKE ? selector.MAKE : "N/A"}{" "}
                                </div>{" "}
                            </aside>
                            <aside>
                                {" "}
                                <p>Model :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.MODEL ? selector.MODEL : "N/A"}{" "}
                                </div>{" "}
                            </aside>
                        </div>
                        <div className="bookin__side2">
                            <aside>
                                <p>Serviced Company :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.BOOKEDINCOMPANY ? selector.BOOKEDINCOMPANY : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                <p>Branch :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.BRANCH ? selector.BRANCH : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                <p>Service Due Date:&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.TOSERVICEDATE
                                        ? moment(selector.TOSERVICEDATE).format("DD/MM/YYYY")
                                        : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                <p>Reported By :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.REPORTEDBY ? selector.BRANCH : "N/A"}
                                </div>{" "}
                            </aside>
                            <aside>
                                <p>Processed Date :&nbsp;</p>
                                <div style={{ color: "red" }}>
                                    {selector.PROCESSEDDATE
                                        ? moment(selector.PROCESSEDDATE).format("DD/MM/YYYY")
                                        : "N/A"}
                                </div>
                            </aside>
                            <aside>
                                {" "}
                                <p>Problems Reported :&nbsp;</p>
                                <div style={{ color: "red", textTransform: "uppercase" }}>
                                    {selector.PROBLEMSREPORTED
                                        ? selector.PROBLEMSREPORTED
                                        : "N/A"}
                                </div>
                            </aside>
                        </div>
                    </main>
                </section>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Userservicedetails