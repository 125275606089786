export const loginInn = (data) => {
    return { type: "LOGINUSER", data: data };
};

export const loginOutt = (data) => {
    return { type: "LOGOUT", data: data };
}

export const vehrigestration = (data) => {
    return { type: "VEHICLE", data: data };
}
export const servicedetails = (data) => {
    return { type: "BOOKINGID", data: data };
}
export const garagedetails = (data) => {
    return { type: "GARAGENUMBER", data: data }
}
export const branchdetails = (data) => {
    return { type: "GARAGEID", data: data }
}
export const clearBranch = (data) => {
    return { type: "CLEAR_BRANCH", data: data }
}
export const loginstate = (data) => {
    return { type: "LOGINSTATE", data: data }
}

