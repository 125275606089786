import React from "react";
import { Link } from "react-router-dom";
import { GiMechanicGarage } from "react-icons/gi";
// import '../Style/Home.css'; // Create a separate CSS file for styling
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginstate, loginInn } from "../Redux/Action/action";
const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div className="btn_links">
        <h2 className="navbar_home font-bold text-lg">
          Service Booking{" "}
          {/* <span
            className="icon__for__forms"
            style={{ fontSize: "25px", width: "20px" }}
          >
            <GiMechanicGarage />{" "}
          </span>{" "} */}
        </h2>
        {/* <h3 className='navbar_home'><Link className='logout_link' to="/home">Dashboard</Link></h3> */}
        <h3 className="navbar_home_one">
          <>
            <Link className="logout_link_2" to="/home">
              Dashboard
            </Link>
          </>
          <button
            className="logout_link_2"
            onClick={() => {
              navigate("/");
              dispatch(loginInn({}));
              dispatch(loginstate(false));
            }}
          >
            Logout{" "}
          </button>
        </h3>
      </div>
    </div>
  );
};

export default Navbar;
