
import { combineReducers } from "redux"
import LoginReduceruser from "./Loginreducer"
import Vehiclereducer from "./vehiclereducer"
import servicereducer from "./servicereducer"
import garagereducer from "./garagereducer"
import branchreducer from "./branchreducer"
import loginstatereducer from "./loginstatereducer"

const rootReducer = combineReducers({
    LoginReduceruser,
    Vehiclereducer,
    servicereducer,
    garagereducer,
    branchreducer,
    loginstatereducer
}
)
export default rootReducer

